import { INetwork, INetworkListElement, INewNetworkSerialized } from '@redux/Network/types';
import getAPI from './request';

export async function createNetwork(network: INewNetworkSerialized): Promise<INetwork> {
  const api = await getAPI();
  const response = await api.api_networks_create({
    data: network,
  });
  return response.body;
}

export async function updateNetwork(network: INetwork): Promise<INetwork> {
  const api = await getAPI();
  const response = await api.api_networks_update({
    uuid: network.uuid,
    data: {
      ...network,
      // @debt - we should remove the need for this serialization and just use id
      new_definition: network.definition.map(e => ({ ...e, uuid: e.id })),
      definition: undefined,
    },
  });
  return response.body;
}

export async function getNetworkList(): Promise<INetworkListElement[]> {
  const api = await getAPI();
  const response = await api.api_networks_list();
  return response.body;
}

export async function getNetworkById(networkUuid: string): Promise<INetwork> {
  const api = await getAPI();
  const response = await api.api_networks_read({
    uuid: networkUuid,
  });
  return response.body;
}
