import { IAsset, IAssetListElement, INewAsset } from '@redux/Assets/types';
import { createStandardAction } from 'typesafe-actions';

export const assetListRequest = createStandardAction('Assets/ASSET_LIST_REQUEST')();

export const assetListSuccess = createStandardAction('Assets/ASSET_LIST_SUCCESS')<{
  list: IAssetListElement[];
}>();

export const assetListError = createStandardAction('Assets/ASSET_LIST_ERROR')<{
  errorMessage: string;
}>();

export const assetRequest = createStandardAction('Assets/ASSET_REQUEST')<{
  assetId: string;
}>();

export const assetSuccess = createStandardAction('Assets/ASSET_SUCCESS')<{
  asset: IAsset;
}>();

export const assetError = createStandardAction('Assets/ASSET_ERROR')<{
  errorMessage: string;
}>();

export const assetSaveRequest = createStandardAction('Assets/ASSET_SAVE_REQUEST')<{
  asset: IAsset | INewAsset;
}>();

export const assetBasicSaveRequest = createStandardAction('Assets/ASSET_BASIC_SAVE_REQUEST')<{
  asset: INewAsset;
}>();

export const assetSaveSuccess = createStandardAction('Assets/ASSET_SAVE_SUCCESS')<{
  asset: IAsset | INewAsset;
}>();

export const assetBasicSaveSuccess = createStandardAction('Assets/ASSET_BASIC_SAVE_SUCCESS')<{
  asset: IAsset;
}>();

export const assetBasicClear = createStandardAction('Assets/ASSET_BASIC_CLEAR')();

export const assetSaveError = createStandardAction('Assets/ASSET_SAVE_ERROR')<{
  errorMessage: string;
}>();

export const assetDeleteRequest = createStandardAction('Assets/ASSET_DELETE_REQUEST')<{
  uuid: string;
}>();
export const assetDeleteSuccess = createStandardAction('Assets/ASSET_DELETE_SUCCESS')<void>();
export const assetDeleteError = createStandardAction('Assets/ASSET_DELETE_ERROR')<{
  errorMessage: string;
}>();

export default {
  assetListRequest,
  assetListSuccess,
  assetListError,
  assetRequest,
  assetSuccess,
  assetError,
  assetSaveRequest,
  assetSaveSuccess,
  assetSaveError,
  assetDeleteRequest,
  assetDeleteSuccess,
  assetDeleteError,
};
