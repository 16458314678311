import { InputGroup } from '@blueprintjs/core';
import { ErrorMessage, FastFieldProps } from 'formik';
import React from 'react';
import styled, { css } from 'styled-components';

const CustomError = styled.div`
  margin-top: 5px;
  color: red;
`;
const CustomInputGroup = styled(props => <InputGroup {...props} />)<{ header?: 'true' | 'false' }>`
  ${props => (props.header === 'true' ? HeaderStyles : '')}
`;
const HeaderStyles = css`
  input {
    height: 6.4rem !important;
    font-size: 3.6rem !important;
  }
`;

type PropsType = FastFieldProps<Record<string, string>> & { header?: 'true' | 'false' };
const CustomInput: React.FunctionComponent<PropsType> = ({
  field,
  form: { touched, errors },
  header,
  ...props
}) => {
  return (
    <React.Fragment>
      <CustomInputGroup
        {...field}
        {...props}
        header={header}
        className={
          errors && errors[field.name] && touched[field.name] ? 'text-input error' : 'text-input'
        }
      />
      <ErrorMessage name={field.name} component={CustomError} />
    </React.Fragment>
  );
};

export default CustomInput;
