import {
  IDashboard,
  INewDashboardSerialized,
  INewTileSerialized,
  ITile,
} from '@redux/Analytics/types';
import getAPI from './request';

export async function createDashboard(dashboard: INewDashboardSerialized): Promise<IDashboard> {
  const api = await getAPI();
  const response = await api.api_dashboards_create({
    data: dashboard,
  });
  return response.body;
}

export async function getDashboardList(): Promise<IDashboard[]> {
  const api = await getAPI();
  const response = await api.api_dashboards_list();
  return response.body;
}

export async function createTile(tile: INewTileSerialized): Promise<ITile> {
  const api = await getAPI();
  const response = await api.api_tiles_create({
    data: tile,
  });
  return response.body;
}

export async function deleteTile(uuid: string): Promise<void> {
  const api = await getAPI();
  await api.api_tiles_delete({
    uuid,
  });
}
