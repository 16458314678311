/* eslint-disable complexity */
import { loginUserSuccess } from '@redux/Login';
import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { networkRequest, networkSuccess } from '.';
import {
  networkCreateError,
  networkCreateRequest,
  networkCreateSuccess,
  networkError,
  networkListError,
  networkListRequest,
  networkListSuccess,
  networkSaveError,
  networkSaveRequest,
  networkSaveSuccess,
} from './actions';
import { INetwork, INetworkListElement } from './types';

export type NetworkAction = ActionType<
  | typeof networkRequest
  | typeof networkSuccess
  | typeof networkError
  | typeof networkListRequest
  | typeof networkListSuccess
  | typeof networkListError
  | typeof networkSaveRequest
  | typeof networkSaveSuccess
  | typeof networkSaveError
  | typeof networkCreateRequest
  | typeof networkCreateSuccess
  | typeof networkCreateError
  | typeof loginUserSuccess
>;

export type NetworkState = Readonly<{
  network: INetwork | null;
  networks: INetworkListElement[];
  networksLoading: boolean;
  networkSaving: boolean;
}>;

const initialState: NetworkState = {
  network: null,
  networks: [],
  networksLoading: false,
  networkSaving: false,
};

const reducer = (state: NetworkState = initialState, action: AnyAction): NetworkState => {
  const typedAction = action as NetworkAction;
  switch (typedAction.type) {
    case getType(networkRequest):
      return { ...state, networksLoading: true, network: initialState.network };
    case getType(networkSuccess):
      return { ...state, networksLoading: false, network: typedAction.payload.network };
    case getType(networkError):
      return { ...state, networksLoading: false };
    case getType(networkListRequest):
      return state;
    case getType(networkListSuccess):
      return {
        ...state,
        networks: typedAction.payload.list,
      };
    case getType(networkListError):
      return {
        ...state,
        networksLoading: false,
        networks: initialState.networks,
      };
    case getType(networkSaveRequest):
      return {
        ...state,
        networkSaving: true,
        network: typedAction.payload.network,
      };
    case getType(networkSaveSuccess):
      return {
        ...state,
        networkSaving: false,
      };
    case getType(networkSaveError):
      return {
        ...state,
        networkSaving: false,
      };
    case getType(networkCreateRequest):
      return {
        ...state,
        networkSaving: true,
        networksLoading: true,
      };
    case getType(networkCreateSuccess):
      return {
        ...state,
        networkSaving: false,
        networksLoading: false,
        network: typedAction.payload.network,
      };
    case getType(networkCreateError):
      return {
        ...state,
        networksLoading: false,
        networkSaving: false,
      };
    case getType(loginUserSuccess):
      return initialState;
    default:
      return state;
  }
};
export default reducer;
