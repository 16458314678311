/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable complexity */
import { loginUserSuccess } from '@redux/Login';
import { AnyAction } from 'redux';
import { mergeGeoJsonFeatures, mergeSimulutionShapesetRunJSON } from 'services/helpers/GeoJSON';
import { ActionType, getType } from 'typesafe-actions';
import { simulationAssetMapAsync, simulationRunAsync } from '.';
import {
  beginSimulationAssetMapPolling,
  beginSimulationShapesMapPolling,
  simuationRunSaveAsync,
  simulationClientAsync,
  simulationClientDeleteAsync,
  simulationClientListAsync,
  simulationClientSaveAsync,
  simulationRealisticDisasterScenarioListAsync,
  simulationShapesMapAsync,
  simulationShapesetListAsync,
  simulationUserPermissionsRequestAsync,
} from './actions';
import {
  FeatureCollection,
  ISimulationClient,
  ISimulationClientListElement,
  ISimulationRealisticDisasterScenario,
  ISimulationRun,
  ISimulationShapeset,
  IUserPermissions,
} from './types';

export type SimulateAction = ActionType<
  | typeof simulationAssetMapAsync
  | typeof simulationClientListAsync
  | typeof simulationClientAsync
  | typeof simulationClientSaveAsync
  | typeof simuationRunSaveAsync
  | typeof simulationRunAsync
  | typeof loginUserSuccess
  | typeof simulationClientDeleteAsync
  | typeof simulationRealisticDisasterScenarioListAsync
  | typeof simulationShapesetListAsync
  | typeof beginSimulationAssetMapPolling
  | typeof simulationUserPermissionsRequestAsync
  | typeof beginSimulationShapesMapPolling
  | typeof simulationShapesMapAsync
>;

export type SimulateState = Readonly<{
  simulationClientList: ISimulationClientListElement[];
  simulationRunSaving: boolean;
  simulationRun: ISimulationRun | null;
  simulationClientListLoading: boolean;
  simulationAssetMapGeoJSON: FeatureCollection | null;
  simulationRealisticDisasterScenarioList: ISimulationRealisticDisasterScenario[];
  simulationShapesetList: ISimulationShapeset[];
  simulationClient: ISimulationClient | null;
  simulationClientIsSaving: boolean;
  selectedSimulationClientID: string | null;
  simulationClientAssetsToLoad: boolean;
  simulationUserPermissions: IUserPermissions[];
}>;

const initialState: SimulateState = {
  simulationClientList: [],
  simulationRunSaving: false,
  simulationRun: null,
  simulationClientListLoading: false,
  simulationAssetMapGeoJSON: null,
  simulationRealisticDisasterScenarioList: [],
  simulationShapesetList: [],
  simulationClient: null,
  simulationClientIsSaving: false,
  selectedSimulationClientID: null,
  simulationClientAssetsToLoad: false,
  simulationUserPermissions: [{ read_only: true }],
};

// eslint-disable-next-line max-lines-per-function
const reducer = (state: SimulateState = initialState, action: AnyAction): SimulateState => {
  const typedAction = action as SimulateAction;
  switch (typedAction.type) {
    case getType(simuationRunSaveAsync.request):
      return { ...state, simulationRunSaving: true };
    case getType(simuationRunSaveAsync.success):
    case getType(simuationRunSaveAsync.failure):
      return { ...state, simulationRunSaving: false };
    case getType(simulationUserPermissionsRequestAsync.request):
      return { ...state };
    case getType(simulationUserPermissionsRequestAsync.success):
      return { ...state, simulationUserPermissions: typedAction.payload.read_only };
    case getType(simulationUserPermissionsRequestAsync.failure):
      return { ...state };
    case getType(simulationClientListAsync.request):
      return { ...state, simulationClientListLoading: true };
    case getType(simulationClientListAsync.success):
      return {
        ...state,
        simulationClientList: typedAction.payload.simulationClientList,
        simulationClientListLoading: false,
      };
    case getType(simulationClientListAsync.failure):
      return { ...state, simulationClientListLoading: false };
    case getType(simulationRealisticDisasterScenarioListAsync.request):
      return { ...state };
    case getType(simulationRealisticDisasterScenarioListAsync.success):
      return {
        ...state,
        simulationRealisticDisasterScenarioList:
          typedAction.payload.simulationRealisticDisasterScenarioList,
      };
    case getType(simulationRealisticDisasterScenarioListAsync.failure):
      return { ...state };
    case getType(simulationClientDeleteAsync.request):
      return { ...state };
    case getType(simulationClientDeleteAsync.success):
      return { ...state };
    case getType(simulationClientDeleteAsync.failure):
      return { ...state };
    case getType(simulationClientAsync.request):
      return { ...state };
    case getType(simulationClientAsync.success):
      return { ...state, simulationClient: typedAction.payload.simulationClient };
    case getType(simulationClientAsync.failure):
      return { ...state };
    case getType(simulationShapesetListAsync.request):
      return { ...state };
    case getType(simulationShapesetListAsync.success):
      return { ...state, simulationShapesetList: typedAction.payload.simulationShapesetList };
    case getType(simulationShapesetListAsync.failure):
      return { ...state };
    case getType(simulationRunAsync.success):
      return { ...state, simulationRun: typedAction.payload.simulationRun };
    case getType(simulationRunAsync.failure):
      return { ...state };
    case getType(simulationRunAsync.request):
      return { ...state, simulationRun: null };
    case getType(loginUserSuccess):
      return initialState;
    case getType(simulationClientSaveAsync.request):
      return { ...state, simulationClientIsSaving: true };
    case getType(simulationClientSaveAsync.success):
      return { ...state, simulationClientIsSaving: false };
    case getType(simulationClientSaveAsync.failure):
      return { ...state, simulationClientIsSaving: false };
    case getType(simulationShapesMapAsync.request):
      return { ...state };
    case getType(simulationShapesMapAsync.success):
      return {
        ...state,
        simulationRun:
          state.simulationRun?.uuid === typedAction.payload.simulationRunUUID
            ? mergeSimulutionShapesetRunJSON(state.simulationRun, typedAction.payload.geojson)
            : state.simulationRun,
      };
    case getType(simulationAssetMapAsync.request):
      return { ...state };
    case getType(beginSimulationShapesMapPolling):
      return { ...state };
    case getType(beginSimulationAssetMapPolling):
      return {
        ...state,
        simulationAssetMapGeoJSON: null,
        selectedSimulationClientID: typedAction.payload.simulationClient,
      };
    case getType(simulationAssetMapAsync.success):
      return {
        ...state,
        simulationAssetMapGeoJSON:
          state.selectedSimulationClientID === typedAction.payload.clientId
            ? mergeGeoJsonFeatures(state.simulationAssetMapGeoJSON, typedAction.payload.geojson)
            : state.simulationAssetMapGeoJSON,
        simulationClientAssetsToLoad: typedAction.payload.largeAssetPolling,
      };
    default:
      return { ...state };
  }
};
export default reducer;
