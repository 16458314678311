import { IRiskListElement, RiskTableElementType } from '@redux/Risks/types';
import { RootState } from '@redux/types';
import { baseOptions } from 'components/Search/Search';
import Fuse from 'fuse.js';

export const riskToRiskTableElement = (risk: IRiskListElement) => {
  const projectNames = risk.projects.reduce(
    (accumulator, project) => accumulator + ' ### ' + project.name,
    '',
  );
  return {
    ...risk,
    projectNames,
  };
};

export const getRiskList = (store: RootState): RiskTableElementType[] => {
  const riskList = store.risk.list;
  return riskList.map(risk => riskToRiskTableElement(risk));
};

export const getSearchValue = (store: RootState): string | undefined => store.risk.searchValue;

export const getFilteredRiskList = (store: RootState): RiskTableElementType[] | undefined => {
  const searchValue = getSearchValue(store);
  if (searchValue === undefined) {
    return undefined;
  }
  const riskList = getRiskList(store);
  const keys = ['asset_title', 'threat_title', 'serial', 'asset_code', 'projectNames'];
  const fuse = new Fuse(riskList, { ...baseOptions, keys });
  return fuse.search(searchValue);
};

export const getAddRiskOverlayOpen = (store: RootState): boolean => store.risk.addRiskOverlayOpen;

export const getRiskSaving = (store: RootState): boolean => store.risk.riskSaving;

export const getAddProjectOverlayOpen = (store: RootState): string | null =>
  store.risk.addProjectOverlayOpen;

export const getProjectSaving = (store: RootState): boolean => store.risk.projectSaving;
