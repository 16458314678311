import React from 'react';
import cascadeLogo from 'static/images/CascadeLogo.svg';
import styled from 'styled-components';

const PageWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -100000;
`;

const InnerBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;
const CascadeLogo = styled.img`
  width: 100%;
  height: 100%;
  position: relative;
`;
const WelcomeMessage = styled.h2`
  text-align: center;
  margin: 15px 0;
`;

const WelcomeSpan = styled.span`
  font-weight: normal;
`;

interface IProps {
  message: [string, string];
}

export default class LoginPage extends React.PureComponent<IProps> {
  render() {
    const { children, message } = this.props;
    return (
      <React.Fragment>
        <PageWrapper>
          <InnerBlock>
            <CascadeLogo src={cascadeLogo} />
            <WelcomeMessage>
              <WelcomeSpan>{message[0]}</WelcomeSpan>
              {message[1]}
            </WelcomeMessage>
            {children}
          </InnerBlock>
        </PageWrapper>
      </React.Fragment>
    );
  }
}
