import { IIntelligence } from '@redux/Intelligence/types';
import { RootState } from '@redux/types';

export const getIntelligence = (store: RootState): IIntelligence | null => {
  if (!store.intelligence.intelligence) {
    return null;
  }
  return { ...store.intelligence.intelligence, new_files: [] };
};

export const getIntelligenceLoading = (store: RootState) => store.intelligence.intelligenceLoading;

export const getIntelligenceSaving = (store: RootState) => store.intelligence.intelligenceSaving;
