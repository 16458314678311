import { Tabs, TabsProps } from '@blueprintjs/core';
import React from 'react';
import styled from 'styled-components';

export const TabTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TextTitleContainer = styled.p`
  margin: 1.6rem 0.4rem;
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

export const CustomTabs = styled((props: TabsProps & { children?: React.ReactNode }) => (
  <Tabs {...props} />
))`
  flex: 4;
  & > .bp3-tab-list {
    justify-content: space-evenly;
  }
`;
