import {
  INewVulnerability,
  INewVulnerabilityGroup,
  IVulnerability,
  IVulnerabilityGroup,
} from '@redux/Assets/types';
import { INewTag, ITag, ITagFilter } from '@redux/Intelligence/types';
import { INewTactic, ITactic } from '@redux/Threats/types';
import axios from 'axios';
import getAPI, { getAxiosConfig } from './request';

export async function createTag(tag: INewTag): Promise<ITag> {
  const api = await getAPI();
  const response = await api.api_tags_create({
    data: tag,
  });
  return response.body;
}

export async function getTags(params?: ITagFilter): Promise<ITag[]> {
  const url = `/api/tags/`;
  const config = await getAxiosConfig(url, 'get', params || {});
  const response = await axios(config);
  return response.data;
}

export async function deleteTag(uuid: string): Promise<void> {
  const api = await getAPI();
  await api.api_tags_delete({
    uuid,
  });
}

export async function getVulnerabilities(): Promise<IVulnerability[]> {
  const api = await getAPI();
  const response = await api.api_vulnerabilities_list();
  return response.body;
}

export async function createVulnerability(
  vulnerability: INewVulnerability,
): Promise<IVulnerability> {
  const api = await getAPI();
  const response = await api.api_vulnerabilities_create({
    data: vulnerability,
  });
  return response.body;
}

export async function deleteVulnerability(uuid: string): Promise<void> {
  const api = await getAPI();
  await api.api_vulnerabilities_delete({
    uuid,
  });
}

export async function getVulnerabilityGroups(): Promise<IVulnerabilityGroup[]> {
  const api = await getAPI();
  const response = await api.api_vulnerability_groups_list();
  return response.body;
}

export async function createVulnerabilityGroup(
  vulnerabilityGroup: INewVulnerabilityGroup,
): Promise<IVulnerabilityGroup> {
  const api = await getAPI();
  const response = await api.api_vulnerability_groups_create({
    data: vulnerabilityGroup,
  });
  return response.body;
}

export async function getTactics(): Promise<ITactic[]> {
  const api = await getAPI();
  const response = await api.api_tactics_list();
  return response.body;
}

export async function createTactic(tactic: INewTactic): Promise<ITactic> {
  const api = await getAPI();
  const response = await api.api_tactics_create({
    data: tactic,
  });
  return response.body;
}

export async function deleteTactic(uuid: string): Promise<void> {
  const api = await getAPI();
  await api.api_tactics_delete({
    uuid,
  });
}
export async function deleteVulnerabilityGroup(uuid: string): Promise<void> {
  const api = await getAPI();
  await api.api_vulnerability_groups_delete({
    uuid,
  });
}
