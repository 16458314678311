import { loginUserRequest } from '@redux/Login/actions';
import { ILoginValues } from '@redux/Login/types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Login from './Login';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  login: (values: ILoginValues) => dispatch(loginUserRequest(values)),
});

export default connect(null, mapDispatchToProps)(Login);
