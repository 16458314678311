/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { LocationChangeAction, RouterState } from 'connected-react-router';
import { combineReducers, Reducer } from 'redux';
import { reducer as analytics } from './Analytics';
import { reducer as asset } from './Assets';
import { reducer as configuration } from './Configuration';
import { reducer as geocoding } from './Geocoding';
import { reducer as intelligence } from './Intelligence';
import { reducer as login } from './Login';
import { reducer as network } from './Network';
import { reducer as risk } from './Risks';
import { reducer as simulate } from './Simulate';
import { reducer as threat } from './Threats';
import { RootAction, RootState } from './types';

/**
 * Example of the Avatar module which should export a reducer.
 */

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers: {
  router: Reducer<RouterState, LocationChangeAction>;
}) {
  return combineReducers<RootState, RootAction>({
    ...asyncReducers,
    login,
    asset,
    threat,
    risk,
    intelligence,
    analytics,
    configuration,
    network,
    simulate,
    geocoding,
  });
}
