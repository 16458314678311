/* eslint-disable no-case-declarations */
/* eslint-disable complexity */
import { loginUserSuccess } from '@redux/Login';
import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import {
  dashboardListError,
  dashboardListRequest,
  dashboardListSuccess,
  dashboardSaveError,
  dashboardSaveRequest,
  dashboardSaveSuccess,
  tileDeleteError,
  tileDeleteRequest,
  tileDeleteSuccess,
  tileSaveError,
  tileSaveRequest,
  tileSaveSuccess,
} from './actions';
import { IDashboard } from './types';

export type AnalyticsAction = ActionType<
  | typeof dashboardListRequest
  | typeof dashboardListSuccess
  | typeof dashboardListError
  | typeof dashboardSaveRequest
  | typeof dashboardSaveSuccess
  | typeof dashboardSaveError
  | typeof tileSaveRequest
  | typeof tileSaveSuccess
  | typeof tileSaveError
  | typeof tileDeleteRequest
  | typeof tileDeleteSuccess
  | typeof tileDeleteError
  | typeof loginUserSuccess
>;

export type AnalyticsState = Readonly<{
  dashboards: IDashboard[];
  dashboardsLoading: boolean;
  dashboardSaving: boolean;
}>;

const initialState: AnalyticsState = {
  dashboards: [],
  dashboardsLoading: false,
  dashboardSaving: false,
};

const reducer = (state: AnalyticsState = initialState, action: AnyAction): AnalyticsState => {
  const typedAction = action as AnalyticsAction;
  switch (typedAction.type) {
    case getType(dashboardListRequest):
      return { ...state, dashboardsLoading: true };
    case getType(dashboardListSuccess):
      return {
        ...state,
        dashboardsLoading: false,
        dashboards: typedAction.payload.list,
      };
    case getType(dashboardListError):
      return {
        ...state,
        dashboardsLoading: false,
        dashboards: initialState.dashboards,
      };
    case getType(dashboardSaveRequest):
      return {
        ...state,
        dashboardSaving: true,
      };
    case getType(dashboardSaveSuccess):
      return {
        ...state,
        dashboardSaving: false,
        dashboards: [...state.dashboards, typedAction.payload.dashboard],
      };
    case getType(dashboardSaveError):
      return {
        ...state,
        dashboardSaving: false,
      };
    case getType(tileSaveSuccess):
      const uuid = typedAction.payload.tile.dashboard;
      const dashboards = state.dashboards.map(dashboard => {
        return dashboard.uuid === uuid
          ? { ...dashboard, tiles: [...dashboard.tiles, typedAction.payload.tile] }
          : dashboard;
      });
      return {
        ...state,
        dashboards,
      };
    case getType(tileSaveRequest):
    case getType(tileSaveError):
      return state;
    case getType(tileDeleteSuccess):
      const dashboardUuid = typedAction.payload.tile.dashboard;
      const tileUuid = typedAction.payload.tile.uuid;
      const newDashboards = state.dashboards.map(dashboard => {
        return dashboard.uuid === dashboardUuid
          ? { ...dashboard, tiles: dashboard.tiles.filter(tile => tile.uuid !== tileUuid) }
          : dashboard;
      });
      return {
        ...state,
        dashboards: newDashboards,
      };
    case getType(tileDeleteError):
    case getType(tileDeleteRequest):
      return state;
    case getType(loginUserSuccess):
      return initialState;
    default:
      return state;
  }
};
export default reducer;
