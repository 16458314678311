import { IVulnerability, IVulnerabilityGroup, KeyedByAssetType } from '@redux/Assets/types';
import { IPrimaryTagIntelligence } from '@redux/Intelligence/types';
import { RootState } from '@redux/types';
import groupBy from 'lodash/groupBy';

export const getTagSaving = (store: RootState): boolean => {
  return store.configuration.tagSaving;
};

export const getTagDeleting = (store: RootState): boolean => {
  return store.configuration.tagDeleting;
};

export const getPrimaryTagIntelligenceList = (store: RootState): IPrimaryTagIntelligence[] => {
  return store.configuration.primaryTagIntelligenceList;
};

export const getPrimaryTagErrorModalOpen = (store: RootState): boolean => {
  return store.configuration.primaryTagErrorModalOpen;
};

export const getTacticList = (store: RootState) => store.configuration.tactics;

export const getTacticsLoading = (store: RootState): boolean => {
  return store.configuration.tacticsLoading;
};

export const getVulnerabilityList = (store: RootState) => store.configuration.vulnerabilities;

export const getVulnerabilitiesByType = (store: RootState): KeyedByAssetType<IVulnerability[]> => {
  const vulnerabilityList = getVulnerabilityList(store);
  return groupBy(vulnerabilityList, 'asset_type');
};

export const getVulnerabilitiesLoading = (store: RootState): boolean => {
  return store.configuration.vulnerabilitiesLoading;
};

export const getVulnerabilityGroupList = (store: RootState) =>
  store.configuration.vulnerabilityGroups;

export const getVulnerabilityGroupsByType = (
  store: RootState,
): KeyedByAssetType<IVulnerabilityGroup[]> => {
  const vulnerabilityGroupList = getVulnerabilityGroupList(store);
  return groupBy(vulnerabilityGroupList, 'asset_type');
};

export const getVulnerabilityGroupsLoading = (store: RootState): boolean => {
  return store.configuration.vulnerabilityGroupsLoading;
};
