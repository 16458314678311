import { createAsyncAction, createStandardAction } from 'typesafe-actions';
import {
  FeatureCollection,
  INewSimulationClient,
  INewSimulationRunListElement,
  ISimulationClient,
  ISimulationClientListElement,
  ISimulationRealisticDisasterScenario,
  ISimulationRun,
  ISimulationRunListElement,
  ISimulationShapeset,
  IUserPermissions,
  ShapeGeoJson,
} from './types';

export const simulationClientListAsync = createAsyncAction(
  'Simulate/SIMULATION_CLIENT_LIST_REQUEST',
  'Simulate/SIMULATION_CLIENT_LIST_SUCCESS',
  'Simulate/SIMULATION_CLIENT_LIST_ERROR',
)<undefined, { simulationClientList: ISimulationClientListElement[] }, { errorMessage: string }>();

export const simulationClientDeleteAsync = createAsyncAction(
  'Simulate/SIMULATION_CLIENT_DELETE_REQUEST',
  'Simulate/SIMULATION_CLIENT_DELETE_SUCCESS',
  'Simulate/SIMULATION_CLIENT_DELETE_ERROR',
)<{ uuid: string }, undefined, { errorMessage: string }>();

export const simulationClientSaveAsync = createAsyncAction(
  'Simulate/SIMULATION_CLIENT_SAVE_REQUEST',
  'Simulate/SIMULATION_CLIENT_SAVE_SUCCESS',
  'Simulate/SIMULATION_CLIENT_SAVE_ERROR',
)<
  { simulationClient: INewSimulationClient | ISimulationClient },
  { simulationClient: ISimulationClient },
  undefined
>();

export const simulationClientAsync = createAsyncAction(
  'Simulate/SIMULATION_CLIENT_REQUEST',
  'Simulate/SIMULATION_CLIENT_SUCCESS',
  'Simulate/SIMULATION_CLIENT_ERROR',
)<{ uuid: string }, { simulationClient: ISimulationClient }, { errorMessage: string }>();

export const simuationRunSaveAsync = createAsyncAction(
  'Simulate/SIMULATION_RUN_SAVE_REQUEST',
  'Simulate/SIMULATION_RUN_SAVE_SUCCESS',
  'Simulate/SIMULATION_RUN_SAVE_ERROR',
)<
  { simulationRun: INewSimulationRunListElement },
  { simulationRun: ISimulationRunListElement },
  { errorMessage: string }
>();

export const simulationRunAsync = createAsyncAction(
  'Simulate/SIMULATION_RUN_REQUEST',
  'Simulate/SIMULATION_RUN_SUCCESS',
  'Simulate/SIMULATION_RUN_ERROR',
)<{ uuid: string }, { simulationRun: ISimulationRun }, { errorMessage: string }>();

export const beginSimulationAssetMapPolling = createStandardAction(
  'Simulate/SIMULATION_ASSET_MAP_POLL',
)<{ simulationClient: string }>();

export const beginSimulationShapesMapPolling = createStandardAction(
  'Simulate/SIMULATION_SHAPES_MAP_POLL',
)<{ shapesetId: string; simulationRunUUID: string }>();

export const simulationAssetMapAsync = createAsyncAction(
  'Simulate/SIMULATION_ASSET_MAP_REQUEST',
  'Simulate/SIMULATION_ASSET_MAP_SUCCESS',
  'Simulate/SIMULATION_ASSET_MAP_ERROR',
)<
  { simulationClient: string; page: number },
  { geojson: FeatureCollection; clientId: string; largeAssetPolling: boolean },
  undefined
>();

export const simulationShapesMapAsync = createAsyncAction(
  'Simulate/SIMULATION_SHAPESET_SHAPES_MAP_REQUEST',
  'Simulate/SIMULATION_SHAPESET_SHAPES_MAP_SUCCESS',
  'Simulate/SIMULATION_SHAPESET_SHAPES_MAP_ERROR',
)<
  { shapesetId: string; simulationRunUUID: string; page: number },
  { geojson: ShapeGeoJson; simulationRunUUID: string; largeAssetPolling: boolean },
  undefined
>();

export const simulationRealisticDisasterScenarioListAsync = createAsyncAction(
  'Simulate/SIMULATION_REALISTIC_DISASTER_SCENARIO_REQUEST',
  'Simulate/SIMULATION_REALISTIC_DISASTER_SCENARIO_SUCCESS',
  'Simulate/SIMULATION_REALISTIC_DISASTER_SCENARIO_ERROR',
)<
  undefined,
  { simulationRealisticDisasterScenarioList: ISimulationRealisticDisasterScenario[] },
  { errorMessage: string }
>();

export const simulationShapesetListAsync = createAsyncAction(
  'Simulate/SIMULATION_SHAPESET_REQUEST',
  'Simulate/SIMULATION_SHAPESET_SUCCESS',
  'Simulate/SIMULATION_SHAPESET_ERROR',
)<undefined, { simulationShapesetList: ISimulationShapeset[] }, { errorMessage: string }>();

export const simulationUserPermissionsRequestAsync = createAsyncAction(
  'Simulate/SIMULATION_USER_PERMISSIONS_REQUEST',
  'Simulate/SIMULATION_USER_PERMISSIONS_SUCCESS',
  'Simulate/SIMULATION_USER_PERMISSIONS_ERROR',
)<undefined, { read_only: IUserPermissions[] }, undefined>();

export default {
  simulationClientListAsync,
  simulationClientDeleteAsync,
  simulationClientSaveAsync,
  simulationClientAsync,
  simuationRunSaveAsync,
  simulationRunAsync,
  simulationAssetMapAsync,
  simulationRealisticDisasterScenarioListAsync,
  simulationShapesetListAsync,
  simulationUserPermissionsRequestAsync,
};
