import { RootState } from '../types';
import {
  AssetFeatureCollection,
  ISimulationClient,
  ISimulationClientListElement,
  ISimulationRealisticDisasterScenario,
  ISimulationRun,
  ISimulationShapeset,
  IUserPermissions,
} from './types';

export const getSimulationClientList = (store: RootState): ISimulationClientListElement[] =>
  store.simulate.simulationClientList;

export const getSimulationClientListLoading = (store: RootState): boolean =>
  store.simulate.simulationClientListLoading;

export const getSimulationClient = (store: RootState): ISimulationClient | null =>
  store.simulate.simulationClient;

export const getSimulationRunSaving = (store: RootState): boolean =>
  store.simulate.simulationRunSaving;

export const getCurrentSimulationRun = (store: RootState): ISimulationRun | null =>
  store.simulate.simulationRun;

export const getSimulationAssetMapGeoJSON = (store: RootState): AssetFeatureCollection | null =>
  store.simulate.simulationAssetMapGeoJSON;

export const getSimulationRealisticDisasterScenarioList = (
  store: RootState,
): ISimulationRealisticDisasterScenario[] => store.simulate.simulationRealisticDisasterScenarioList;

export const getSimulationShapeset = (store: RootState): ISimulationShapeset[] =>
  store.simulate.simulationShapesetList;

export const getSimulationClientIsSaving = (store: RootState): boolean =>
  store.simulate.simulationClientIsSaving;

export const getcurrentSimulationClientID = (store: RootState): string | null =>
  store.simulate.selectedSimulationClientID;

export const getLargeClientAssetsLoading = (store: RootState): boolean =>
  store.simulate.simulationClientAssetsToLoad;

export const getSimulationUserPerms = (store: RootState): IUserPermissions[] =>
  store.simulate.simulationUserPermissions;
