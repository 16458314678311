import { getEntitySaveFormData } from '@redux/utils/savingFormData';
import { displayRequestErrorToaster } from '@redux/utils/savingToaster';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createGeocodingAssetCsv,
  getGeocodingCsvList,
  getGeocodingOutput,
} from 'services/networking/geocoding';
import { parseError } from 'services/networking/parseError';
import { ActionType, getType } from 'typesafe-actions';
import {
  geocodingAssetCsvGetRequest,
  geocodingAssetCsvGetSuccess,
  geocodingAssetCsvListRequest,
  geocodingAssetCsvListSuccess,
  geocodingAssetCsvSaveRequest,
  geocodingAssetCsvSaveSuccess,
} from '.';
import { IGeocodingAssetCsv } from './types';

export function* geocodingAssetCsvSaveRequestSaga(
  action: ActionType<typeof geocodingAssetCsvSaveRequest>,
) {
  try {
    const data = getEntitySaveFormData(
      {
        input: action.payload.geocodingAssetCsv.input,
        cells_to_geocode: action.payload.selectedCells,
      },
      [],
    );

    const geocodingAssetCsv: IGeocodingAssetCsv = yield call(createGeocodingAssetCsv, data);
    yield put(geocodingAssetCsvSaveSuccess({ geocodingAssetCsv }));
  } catch (error) {
    const { responseStatus } = parseError(error);
    displayRequestErrorToaster(responseStatus);
  }
}

export function* geocodingAssetCsvGetRequestSaga(
  action: ActionType<typeof geocodingAssetCsvGetRequest>,
) {
  try {
    const uuid = action.payload.geocodingAssetCsvUuid;
    const geocodingAssetCsv: IGeocodingAssetCsv = yield call(getGeocodingOutput, uuid);
    yield put(geocodingAssetCsvGetSuccess({ geocodingAssetCsv }));
  } catch (error) {
    const { responseStatus } = parseError(error);
    displayRequestErrorToaster(responseStatus);
  }
}

export function* geocodingAssetCsvListRequestSaga() {
  try {
    const geocodingAssetCsvList: IGeocodingAssetCsv[] = yield call(getGeocodingCsvList);
    yield put(geocodingAssetCsvListSuccess({ geocodingAssetCsvList }));
  } catch (error) {
    const { responseStatus } = parseError(error);
    displayRequestErrorToaster(responseStatus);
  }
}

export default function* geocodingSaga() {
  yield takeEvery(getType(geocodingAssetCsvSaveRequest), geocodingAssetCsvSaveRequestSaga);
  yield takeEvery(getType(geocodingAssetCsvGetRequest), geocodingAssetCsvGetRequestSaga);
  yield takeEvery(getType(geocodingAssetCsvListRequest), geocodingAssetCsvListRequestSaga);
}
