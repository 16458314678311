/* eslint-disable complexity */
import { loginUserSuccess } from '@redux/Login';
import { IRiskListElement } from '@redux/Risks/types';
import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import {
  projectSaveError,
  projectSaveRequest,
  projectSaveSuccess,
  riskDeleteError,
  riskDeleteRequest,
  riskDeleteSuccess,
  riskListError,
  riskListSuccess,
  riskSaveError,
  riskSaveRequest,
  riskSaveSuccess,
  saveRisksFilterValue,
  setAddProjectOverlay,
  toggleAddRiskOverlay,
} from './actions';

export type RiskAction = ActionType<
  | typeof riskListSuccess
  | typeof riskListError
  | typeof toggleAddRiskOverlay
  | typeof riskSaveRequest
  | typeof riskSaveSuccess
  | typeof riskSaveError
  | typeof projectSaveRequest
  | typeof projectSaveSuccess
  | typeof projectSaveError
  | typeof setAddProjectOverlay
  | typeof saveRisksFilterValue
  | typeof riskDeleteRequest
  | typeof riskDeleteSuccess
  | typeof riskDeleteError
  | typeof loginUserSuccess
>;

export type RiskState = Readonly<{
  list: IRiskListElement[];
  riskListErrorMessage: string | null;
  addRiskOverlayOpen: boolean;
  riskSaving: boolean;
  addProjectOverlayOpen: null | string;
  projectSaving: boolean;
  searchValue: string | undefined;
}>;

const initialState: RiskState = {
  list: [],
  riskListErrorMessage: null,
  addRiskOverlayOpen: false,
  riskSaving: false,
  addProjectOverlayOpen: null,
  projectSaving: false,
  searchValue: undefined,
};

const reducer = (state: RiskState = initialState, action: AnyAction): RiskState => {
  const typedAction = action as RiskAction;
  switch (typedAction.type) {
    case getType(riskListSuccess):
      return {
        ...state,
        list: typedAction.payload.list,
      };
    case getType(riskListError):
      return {
        ...state,
        list: initialState.list,
        riskListErrorMessage: typedAction.payload.errorMessage,
      };
    case getType(toggleAddRiskOverlay):
      return {
        ...state,
        addRiskOverlayOpen: !state.addRiskOverlayOpen,
      };
    case getType(riskSaveRequest):
      return {
        ...state,
        riskSaving: true,
      };
    case getType(riskSaveSuccess):
      return {
        ...state,
        riskSaving: false,
        addRiskOverlayOpen: false,
      };
    case getType(riskSaveError):
      return {
        ...state,
        riskSaving: false,
      };
    case getType(riskDeleteRequest):
    case getType(riskDeleteSuccess):
    case getType(riskDeleteError):
      return state;
    case getType(setAddProjectOverlay):
      return {
        ...state,
        addProjectOverlayOpen: typedAction.payload.risk,
      };
    case getType(projectSaveRequest):
      return {
        ...state,
        projectSaving: true,
      };
    case getType(projectSaveSuccess):
      return {
        ...state,
        projectSaving: false,
        addProjectOverlayOpen: null,
      };
    case getType(projectSaveError):
      return {
        ...state,
        projectSaving: false,
      };
    case getType(saveRisksFilterValue):
      return {
        ...state,
        searchValue: typedAction.payload.searchValue,
      };
    case getType(loginUserSuccess):
      return initialState;
    default:
      return state;
  }
};

export default reducer;
