import { Intent } from '@blueprintjs/core';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  changePassword,
  getSimulateEnabled,
  login,
  logout,
} from 'services/networking/authenticate';
import { parseError } from 'services/networking/parseError';
import { update_token } from 'services/networking/request';
import { FeedbackToaster } from 'services/toaster';
import { ActionType, getType } from 'typesafe-actions';
import { simulateEnabledError, simulateEnabledRequest, simulateEnabledSuccess } from '.';
import { history } from '../../index';
import { urls } from '../../routes';
import {
  changePasswordError,
  changePasswordRequest,
  changePasswordSuccess,
  loginUserError,
  loginUserRequest,
  loginUserSuccess,
} from './actions';

export function* loginUserRequestSaga(action: ActionType<typeof loginUserRequest>) {
  try {
    const token: { access: string } = yield call(login, action.payload);
    yield call(update_token, token.access);
    yield put(loginUserSuccess());
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(loginUserError({ errorMessage, status: responseStatus }));
  }
}

export function* loginUserSuccessSaga(_: ActionType<typeof loginUserSuccess>) {
  yield history.push(urls.HOME);
  yield put(simulateEnabledRequest());
}

export function* loginUserFailedSaga(action: ActionType<typeof loginUserError>) {
  if (action.payload.status === 403) {
    FeedbackToaster.show({
      message: 'Too many failed login attempts, please try again later',
      intent: Intent.DANGER,
      timeout: 3000,
    });
  } else {
    FeedbackToaster.show({
      message: 'User or password not ok',
      intent: Intent.DANGER,
      timeout: 3000,
    });
  }
  yield;
}

export function* changePasswordRequestSaga(action: ActionType<typeof changePasswordRequest>) {
  try {
    yield call(changePassword, action.payload);
    yield call(logout);
    const message = `Password successfully changed`;
    FeedbackToaster.show({
      message,
      intent: Intent.SUCCESS,
      timeout: 3000,
    });
    yield put(changePasswordSuccess());
  } catch (error) {
    const { errorMessage, errorStatus, response } = parseError<{
      body: {
        current_password: string;
        new_password: string;
      };
    }>(error);
    yield put(changePasswordError({ errorMessage }));
    if (errorStatus === 400) {
      if (response.body.current_password) {
        const message = `Your password is incorrect`;
        FeedbackToaster.show({
          message,
          intent: Intent.DANGER,
          timeout: 3000,
        });
      }
      if (response.body.new_password) {
        const message = `Your password can't be too similar to your other personal information.\n
        Your password must contain at least 8 characters.
        Your password can't be a commonly used password.
        Your password can't be entirely numeric.
        Your password must contain at least 1 digit, 0-9,
        at least 1 uppercase letter, A-Z,
        at least 1 lowercase letter, a-z,
        and at least 1 symbol: ()[]{}|\`~!@#$%^&*_-+=;:'",<>./?`;
        FeedbackToaster.show({
          message,
          intent: Intent.DANGER,
          timeout: 3000,
        });
      }
    } else {
      const message = 'Something went wrong. Try submitting again';
      FeedbackToaster.show({
        message,
        intent: Intent.DANGER,
        timeout: 3000,
      });
    }
  }
}

export function* simulateEnabledRequestSaga() {
  try {
    const simulateEnabled: boolean = yield call(getSimulateEnabled);
    yield put(simulateEnabledSuccess({ simulateEnabled }));
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(simulateEnabledError({ errorMessage }));
  }
}

export default function* loginUserSaga() {
  yield call(simulateEnabledRequestSaga);
  yield takeEvery(getType(loginUserRequest), loginUserRequestSaga);
  yield takeEvery(getType(loginUserSuccess), loginUserSuccessSaga);
  yield takeEvery(getType(loginUserError), loginUserFailedSaga);
  yield takeEvery(getType(changePasswordRequest), changePasswordRequestSaga);
  yield takeEvery(getType(simulateEnabledRequest), simulateEnabledRequestSaga);
}
