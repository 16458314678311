import Fuse from 'fuse.js';
import React, { FormEvent } from 'react';

interface IProps<T extends unknown, K extends keyof T> {
  keys: K[];
  dataList: T[];
  setDataList: (dataList?: T[]) => void;
}

export const baseOptions = {
  shouldSort: true,
  threshold: 0.3,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
};

export default class Search<T, K extends keyof T> extends React.PureComponent<IProps<T, K>> {
  render() {
    return (
      <input
        className="bp3-input-group bp3-input"
        onChange={this.setDataList}
        placeholder="Search..."
      />
    );
  }
  setDataList = (event: FormEvent<HTMLInputElement>) => {
    const { dataList, setDataList, keys } = this.props;
    if (event.currentTarget.value.length) {
      const fuse = new Fuse(dataList, { ...baseOptions, keys });
      const results = fuse.search(event.currentTarget.value);
      return setDataList(results);
    }
    setDataList(undefined);
  };
}
