/* eslint-disable max-lines */
import { IVulnerability, IVulnerabilityGroup } from '@redux/Assets/types';
import { IPrimaryTagIntelligence, ITag } from '@redux/Intelligence/types';
import { ITactic } from '@redux/Threats/types';
import {
  displayDeletingSuccessMessage,
  displayRequestErrorToaster,
  displaySavingSuccessMessage,
} from '@redux/utils/savingToaster';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  createTactic,
  createTag,
  createVulnerability,
  createVulnerabilityGroup,
  deleteTactic,
  deleteTag,
  deleteVulnerability,
  deleteVulnerabilityGroup,
  getTactics,
  getVulnerabilities,
  getVulnerabilityGroups,
} from 'services/networking/configuration';
import { parseError } from 'services/networking/parseError';
import { ActionType, getType } from 'typesafe-actions';
import {
  getVulnerabilityGroupList,
  vulnerabilityDeleteError,
  vulnerabilityDeleteRequest,
  vulnerabilityDeleteSuccess,
  vulnerabilityGroupDeleteError,
  vulnerabilityGroupDeleteSuccess,
  vulnerabilityGroupListError,
  vulnerabilityGroupListSuccess,
  vulnerabilityGroupSaveRequest,
} from '.';
import {
  tacticDeleteError,
  tacticDeleteRequest,
  tacticDeleteSuccess,
  tacticListError,
  tacticListRequest,
  tacticListSuccess,
  tacticSaveError,
  tacticSaveRequest,
  tacticSaveSuccess,
  tagDeleteError,
  tagDeletePrimaryError,
  tagDeleteRequest,
  tagDeleteSuccess,
  tagSaveError,
  tagSaveRequest,
  tagSaveSuccess,
  vulnerabilityGroupDeleteRequest,
  vulnerabilityGroupListRequest,
  vulnerabilityGroupSaveError,
  vulnerabilityGroupSaveSuccess,
  vulnerabilityListError,
  vulnerabilityListRequest,
  vulnerabilityListSuccess,
  vulnerabilitySaveError,
  vulnerabilitySaveRequest,
  vulnerabilitySaveSuccess,
} from './actions';
import { getTacticList, getVulnerabilityList } from './selectors';

interface PrimaryTagIntelligenceResponse {
  status: number;
  body: IPrimaryTagIntelligence[];
}

export function* tagSaveRequestSaga(action: ActionType<typeof tagSaveRequest>) {
  try {
    const tag: ITag = yield call(createTag, action.payload.tag);
    yield put(tagSaveSuccess({ tag }));
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(tagSaveError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}

export function tagSaveSuccessSaga(_: ActionType<typeof tagSaveSuccess>) {
  displaySavingSuccessMessage('tag');
}

export function* tagDeleteRequestSaga(action: ActionType<typeof tagDeleteRequest>) {
  try {
    yield call(deleteTag, action.payload.uuid);
    yield put(tagDeleteSuccess({ uuid: action.payload.uuid }));
  } catch (error) {
    const { errorMessage, errorStatus, responseStatus, response } =
      parseError<PrimaryTagIntelligenceResponse>(error);
    if (responseStatus === 405) {
      yield put(tagDeletePrimaryError({ list: response.body }));
    } else {
      yield put(tagDeleteError({ errorMessage }));
      displayRequestErrorToaster(errorStatus);
    }
  }
}
export function tagDeleteSuccessSaga(_: ActionType<typeof tagDeleteSuccess>) {
  displayDeletingSuccessMessage('tag');
}

export function* tacticListRequestSaga(_: ActionType<typeof tacticListRequest>) {
  try {
    const currentTacticList: ITactic[] = yield select(getTacticList);
    if (currentTacticList.length === 0) {
      const tacticList: ITactic[] = yield call(getTactics);
      yield put(tacticListSuccess({ list: tacticList }));
    } else {
      yield put(tacticListSuccess({ list: currentTacticList }));
    }
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(tacticListError({ errorMessage }));
  }
}

export function* tacticSaveRequestSaga(action: ActionType<typeof tacticSaveRequest>) {
  try {
    const tactic: ITactic = yield call(createTactic, action.payload.tactic);
    yield put(tacticSaveSuccess({ tactic }));
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(tacticSaveError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}

export function* tacticDeleteRequestSaga(action: ActionType<typeof tacticDeleteRequest>) {
  try {
    yield call(deleteTactic, action.payload.uuid);
    yield put(tacticDeleteSuccess(action.payload.uuid));
  } catch (error) {
    const { errorMessage, errorStatus } = parseError(error);
    yield put(tacticDeleteError({ errorMessage }));
    displayRequestErrorToaster(errorStatus);
  }
}

export function* vulnerabilityListRequestSaga(_: ActionType<typeof vulnerabilityListRequest>) {
  try {
    const currentVulnerabilityList: IVulnerability[] = yield select(getVulnerabilityList);
    if (currentVulnerabilityList.length === 0) {
      const vulnerabilityList: IVulnerability[] = yield call(getVulnerabilities);
      yield put(vulnerabilityListSuccess({ list: vulnerabilityList }));
    } else {
      yield put(vulnerabilityListSuccess({ list: currentVulnerabilityList }));
    }
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(vulnerabilityListError({ errorMessage }));
  }
}

export function* vulnerabilityListFailedSaga(_: ActionType<typeof vulnerabilityListError>) {
  yield alert('Error while retrieving the vulnerability list');
}

export function* vulnerabilitySaveRequestSaga(action: ActionType<typeof vulnerabilitySaveRequest>) {
  try {
    const vulnerability: IVulnerability = yield call(
      createVulnerability,
      action.payload.vulnerability,
    );

    yield put(vulnerabilitySaveSuccess({ vulnerability }));
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(vulnerabilitySaveError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}

export function* vulnerabilityDeleteRequestSaga(
  action: ActionType<typeof vulnerabilityDeleteRequest>,
) {
  try {
    yield call(deleteVulnerability, action.payload.vulnerabilityUuid);
    yield put(vulnerabilityDeleteSuccess(action.payload.vulnerabilityUuid));
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(vulnerabilityDeleteError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}

export function* vulnerabilityGroupListRequestSaga(
  _: ActionType<typeof vulnerabilityGroupListRequest>,
) {
  try {
    const currentVulnerabilityGroupList: IVulnerabilityGroup[] = yield select(
      getVulnerabilityGroupList,
    );
    if (currentVulnerabilityGroupList.length === 0) {
      const vulnerabilityGroupList: IVulnerabilityGroup[] = yield call(getVulnerabilityGroups);
      yield put(vulnerabilityGroupListSuccess({ list: vulnerabilityGroupList }));
    } else {
      yield put(vulnerabilityGroupListSuccess({ list: currentVulnerabilityGroupList }));
    }
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(vulnerabilityGroupListError({ errorMessage }));
  }
}
export function* vulnerabilityGroupListFailedSaga(
  _: ActionType<typeof vulnerabilityGroupListError>,
) {
  yield alert('Error while retrieving the vulnerability group list');
}

export function* vulnerabilityGroupSaveRequestSaga(
  action: ActionType<typeof vulnerabilityGroupSaveRequest>,
) {
  try {
    const vulnerabilityGroup: IVulnerabilityGroup = yield call(
      createVulnerabilityGroup,
      action.payload.vulnerabilityGroup,
    );

    yield put(vulnerabilityGroupSaveSuccess({ vulnerabilityGroup }));
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(vulnerabilityGroupSaveError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}
export function* vulnerabilityGroupDeleteRequestSaga(
  action: ActionType<typeof vulnerabilityGroupDeleteRequest>,
) {
  try {
    yield call(deleteVulnerabilityGroup, action.payload.vulnerabilityGroupUuid);
    yield put(vulnerabilityGroupDeleteSuccess(action.payload.vulnerabilityGroupUuid));
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(vulnerabilityGroupDeleteError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}

export default function* configurationSaga() {
  yield takeEvery(getType(tagDeleteRequest), tagDeleteRequestSaga);
  yield takeEvery(getType(tagSaveRequest), tagSaveRequestSaga);
  yield takeEvery(getType(tagSaveSuccess), tagSaveSuccessSaga);
  yield takeEvery(getType(tagDeleteSuccess), tagDeleteSuccessSaga);
  yield takeEvery(getType(tacticListRequest), tacticListRequestSaga);
  yield takeEvery(getType(tacticSaveRequest), tacticSaveRequestSaga);
  yield takeEvery(getType(tacticDeleteRequest), tacticDeleteRequestSaga);
  yield takeEvery(getType(vulnerabilityListRequest), vulnerabilityListRequestSaga);
  yield takeEvery(getType(vulnerabilityListError), vulnerabilityListFailedSaga);
  yield takeEvery(getType(vulnerabilitySaveRequest), vulnerabilitySaveRequestSaga);
  yield takeEvery(getType(vulnerabilityDeleteRequest), vulnerabilityDeleteRequestSaga);
  yield takeEvery(getType(vulnerabilityGroupListRequest), vulnerabilityGroupListRequestSaga);
  yield takeEvery(getType(vulnerabilityGroupListError), vulnerabilityGroupListFailedSaga);
  yield takeEvery(getType(vulnerabilityGroupSaveRequest), vulnerabilityGroupSaveRequestSaga);
  yield takeEvery(getType(vulnerabilityGroupDeleteRequest), vulnerabilityGroupDeleteRequestSaga);
}
