import {
  INewVulnerability,
  INewVulnerabilityGroup,
  IVulnerability,
  IVulnerabilityGroup,
} from '@redux/Assets/types';
import { INewTag, IPrimaryTagIntelligence, ITag } from '@redux/Intelligence/types';
import { INewTactic, ITactic } from '@redux/Threats/types';
import { createStandardAction } from 'typesafe-actions';

export const tagSaveRequest = createStandardAction('Configuration/TAG_SAVE_REQUEST')<{
  tag: INewTag;
}>();

export const tagSaveSuccess = createStandardAction('Configuration/TAG_SAVE_SUCCESS')<{
  tag: ITag;
}>();
export const tagSaveError = createStandardAction('Configuration/TAG_SAVE_ERROR')<{
  errorMessage: string;
}>();

export const tagDeleteRequest = createStandardAction('Configuration/TAG_DELETE_REQUEST')<{
  uuid: string;
}>();

export const tagDeleteSuccess = createStandardAction('Configuration/TAG_DELETE_SUCCESS')<{
  uuid: string;
}>();

export const tagDeleteError = createStandardAction('Configuration/TAG_DELETE_ERROR')<{
  errorMessage: string;
}>();

export const tagDeletePrimaryError = createStandardAction(
  'Configuration/TAG_DELETE_PRIMARY_ERROR',
)<{
  list: IPrimaryTagIntelligence[];
}>();

export const tagDeletePrimaryClear = createStandardAction(
  'Configuration/TAG_DELETE_PRIMARY_CLEAR',
)();

export const tacticListRequest = createStandardAction('Configuration/TACTICS_LIST_REQUEST')();

export const tacticListSuccess = createStandardAction('Configuration/TACTICS_LIST_SUCCESS')<{
  list: ITactic[];
}>();

export const tacticListError = createStandardAction('Configuration/TACTICS_LIST_ERROR')<{
  errorMessage: string;
}>();

export const tacticSaveRequest = createStandardAction('Configuration/TACTIC_SAVE_REQUEST')<{
  tactic: INewTactic;
}>();

export const tacticSaveSuccess = createStandardAction('Configuration/TACTIC_SAVE_SUCCESS')<{
  tactic: ITactic;
}>();

export const tacticSaveError = createStandardAction('Configuration/TACTIC_SAVE_ERROR')<{
  errorMessage: string;
}>();

export const tacticDeleteRequest = createStandardAction('Configuration/TACTIC_DELETE_REQUEST')<{
  uuid: string;
}>();

export const tacticDeleteSuccess = createStandardAction(
  'Configuration/TACTIC_DELETE_SUCCESS',
)<string>();

export const tacticDeleteError = createStandardAction('Configuration/TACTIC_DELETE_ERROR')<{
  errorMessage: string;
}>();

export const vulnerabilityListRequest = createStandardAction(
  'Configuration/VULNERABILITY_LIST_REQUEST',
)();

export const vulnerabilityListSuccess = createStandardAction(
  'Configuration/VULNERABILITY_LIST_SUCCESS',
)<{
  list: IVulnerability[];
}>();

export const vulnerabilityListError = createStandardAction(
  'Configuration/VULNERABILITY_LIST_ERROR',
)<{
  errorMessage: string;
}>();

export const vulnerabilitySaveRequest = createStandardAction(
  'Configuration/VULNERABILITY_SAVE_REQUEST',
)<{
  vulnerability: INewVulnerability;
}>();

export const vulnerabilitySaveSuccess = createStandardAction(
  'Configuration/VULNERABILITY_SAVE_SUCCESS',
)<{
  vulnerability: IVulnerability;
}>();
export const vulnerabilitySaveError = createStandardAction(
  'Configuration/VULNERABILITY_SAVE_ERROR',
)<{
  errorMessage: string;
}>();

export const vulnerabilityDeleteRequest = createStandardAction(
  'Configuration/VULNERABILITY_DELETE_REQUEST',
)<{
  vulnerabilityUuid: string;
}>();

export const vulnerabilityDeleteSuccess = createStandardAction(
  'Configuration/VULNERABILITY_DELETE_SUCCESS',
)<string>();

export const vulnerabilityDeleteError = createStandardAction(
  'Configuration/VULNERABILITY_DELETE_ERROR',
)<{
  errorMessage: string;
}>();

export const vulnerabilityGroupListRequest = createStandardAction(
  'Configuration/VULNERABILITY_GROUP_LIST_REQUEST',
)();

export const vulnerabilityGroupListSuccess = createStandardAction(
  'Configuration/VULNERABILITY_GROUP_LIST_SUCCESS',
)<{
  list: IVulnerabilityGroup[];
}>();

export const vulnerabilityGroupListError = createStandardAction(
  'Configuration/VULNERABILITY_GROUP_LIST_ERROR',
)<{
  errorMessage: string;
}>();

export const vulnerabilityGroupSaveRequest = createStandardAction(
  'Configuration/VULNERABILITY_GROUP_SAVE_REQUEST',
)<{
  vulnerabilityGroup: INewVulnerabilityGroup;
}>();

export const vulnerabilityGroupSaveSuccess = createStandardAction(
  'Configuration/VULNERABILITY_GROUP_SAVE_SUCCESS',
)<{
  vulnerabilityGroup: IVulnerabilityGroup;
}>();
export const vulnerabilityGroupSaveError = createStandardAction(
  'Configuration/VULNERABILITY_GROUP_SAVE_ERROR',
)<{
  errorMessage: string;
}>();

export const vulnerabilityGroupDeleteRequest = createStandardAction(
  'Configuration/VULNERABILITY_GROUP_DELETE_REQUEST',
)<{
  vulnerabilityGroupUuid: string;
}>();

export const vulnerabilityGroupDeleteSuccess = createStandardAction(
  'Configuration/VULNERABILITY_GROUP_DELETE_SUCCESS',
)<string>();

export const vulnerabilityGroupDeleteError = createStandardAction(
  'Configuration/VULNERABILITY_GROUP_DELETE_ERROR',
)<{
  errorMessage: string;
}>();
