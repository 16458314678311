import {
  displayDeletingSuccessMessage,
  displayRequestErrorToaster,
  displaySavingSuccessMessage,
} from '@redux/utils/savingToaster';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createDashboard,
  createTile,
  deleteTile,
  getDashboardList,
} from 'services/networking/analytics';
import { parseError } from 'services/networking/parseError';
import { ActionType, getType } from 'typesafe-actions';
import { history } from '../../index';
import urls from '../../routes/urls';
import {
  dashboardListError,
  dashboardListRequest,
  dashboardListSuccess,
  dashboardSaveError,
  dashboardSaveRequest,
  dashboardSaveSuccess,
  tileDeleteError,
  tileDeleteRequest,
  tileDeleteSuccess,
  tileSaveError,
  tileSaveRequest,
  tileSaveSuccess,
} from './actions';
import { IDashboard, ITile } from './types';

export function* dashboardListRequestSaga(_: ActionType<typeof dashboardListRequest>) {
  try {
    const dashboardList: IDashboard[] = yield call(getDashboardList);
    yield put(dashboardListSuccess({ list: dashboardList }));
  } catch (error) {
    yield put(dashboardListError({ errorMessage: error instanceof Error ? error.message : '' }));
  }
}
export function* dashboardListFailedSaga(_: ActionType<typeof dashboardListError>) {
  yield alert('Error while retrieving the dashboards');
}

export function* dashboardSaveRequestSaga(action: ActionType<typeof dashboardSaveRequest>) {
  try {
    const dashboard: IDashboard = yield call(createDashboard, action.payload.dashboard);
    yield put(dashboardSaveSuccess({ dashboard }));
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(dashboardSaveError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}
export function* dashboardSaveSuccessSaga(action: ActionType<typeof dashboardSaveSuccess>) {
  yield history.push(`${urls.ANALYTICS}/dashboard/${action.payload.dashboard.uuid}`);
  displaySavingSuccessMessage('dashboard');
}

export function* tileSaveRequestSaga(action: ActionType<typeof tileSaveRequest>) {
  try {
    const tile: ITile = yield call(createTile, action.payload.tile);
    yield put(tileSaveSuccess({ tile }));
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(tileSaveError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}
export function tileSaveSuccessSaga(_: ActionType<typeof tileSaveSuccess>) {
  displaySavingSuccessMessage('tile');
}

export function* tileDeleteRequestSaga(action: ActionType<typeof tileDeleteRequest>) {
  try {
    yield call(deleteTile, action.payload.tile.uuid);
    yield put(tileDeleteSuccess({ tile: action.payload.tile }));
  } catch (error) {
    const { errorMessage, errorStatus } = parseError(error);
    yield put(tileDeleteError({ errorMessage }));
    displayRequestErrorToaster(errorStatus);
  }
}
export function tileDeleteSuccessSaga(_: ActionType<typeof tileDeleteSuccess>) {
  displayDeletingSuccessMessage('tile');
}

export default function* analyticsSaga() {
  yield takeEvery(getType(dashboardListRequest), dashboardListRequestSaga);
  yield takeEvery(getType(dashboardListError), dashboardListFailedSaga);
  yield takeEvery(getType(dashboardSaveRequest), dashboardSaveRequestSaga);
  yield takeEvery(getType(dashboardSaveSuccess), dashboardSaveSuccessSaga);
  yield takeEvery(getType(tileSaveRequest), tileSaveRequestSaga);
  yield takeEvery(getType(tileSaveSuccess), tileSaveSuccessSaga);
  yield takeEvery(getType(tileDeleteRequest), tileDeleteRequestSaga);
  yield takeEvery(getType(tileDeleteSuccess), tileDeleteSuccessSaga);
}
