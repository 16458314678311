import { Intent } from '@blueprintjs/core';
import { AxiosRequestConfig, Method } from 'axios';
import jwt_decode from 'jwt-decode';
import qs from 'qs';
import { FeedbackToaster } from 'services/toaster';
import Swagger from 'swagger-client';
import { history } from '../../index';
import { urls } from '../../routes';

let encodedToken: string | null;
let decodedToken: { exp: number } | null;
let client: any;

async function create_client(token: string): Promise<void> {
  client = await Swagger({
    url: '/swagger.json',
    requestInterceptor: (req: { headers: { Authorization: string } }) => {
      req.headers.Authorization = `Bearer ${token}`;
      return req;
    },
  });
}

function getTimestampInSeconds(): number {
  return new Date().getTime() / 1000;
}

function isTokenValid(expirationDateInSeconds: number): boolean {
  return expirationDateInSeconds - getTimestampInSeconds() < 10;
}

export async function update_token(token: string) {
  await create_client(token);
  encodedToken = token;
  decodedToken = jwt_decode(token);
}

export function deleteToken() {
  encodedToken = null;
  decodedToken = null;
}

async function refreshAccessToken() {
  if (!decodedToken || isTokenValid(decodedToken.exp)) {
    const request = {
      url: `/auth/jwt/refresh`,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await Swagger.http(request);
      await update_token(response.body.access);
    } catch (e) {
      history.push(urls.LOGIN);
      const message = 'Session timed out. Please reauthenticate.';
      FeedbackToaster.show({
        message,
        intent: Intent.DANGER,
        timeout: 3000,
      });
      throw e;
    }
  }
}
export default async function getAPI() {
  await refreshAccessToken();
  return client.apis.api;
}

export async function getHttpRequest(url: string, method: string, body?: string) {
  await refreshAccessToken();
  return {
    url,
    method,
    body,
    headers: { Authorization: `Bearer ${encodedToken}`, 'Content-Type': 'application/json' },
  };
}

export async function getAxiosConfig(url: string, method: Method, params: unknown = {}) {
  await refreshAccessToken();
  return {
    method,
    url,
    headers: { Authorization: `Bearer ${encodedToken}` },
    params,
    paramsSerializer: (parameters: unknown) => qs.stringify(parameters, { arrayFormat: 'repeat' }),
  };
}

export async function getAxiosFormDataConfig(
  url: string,
  method: Method,
  payload: FormData,
): Promise<AxiosRequestConfig> {
  const config = await getAxiosConfig(url, method);
  return {
    ...config,
    data: payload,
  };
}
