import keys from 'lodash/keys';

type Message = string | INestedDictionary;
interface INestedDictionary {
  [x: string]: Message;
}
interface IFlattenedDictionary {
  [x: string]: string;
}

export const flattenMessages = (
  nestedMessages: INestedDictionary,
  prefix = '',
): IFlattenedDictionary =>
  keys(nestedMessages).reduce((messages: IFlattenedDictionary, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});

export default flattenMessages;
