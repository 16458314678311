import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { logout } from 'services/networking/authenticate';
import cascadeLogo from 'static/images/CascadeLogo.svg';
import cog from 'static/images/SettingsCog.svg';
import { history } from '../../index';
import { urls } from '../../routes';
import NavBarTabs from './NavBarTabs';
import {
  CascadeIcon,
  CascadeTitle,
  Logo,
  NavBarContainer,
  Navigate,
  SeparatorBar,
  SettingsCog,
} from './PageLayout.styles';

class PageLayout extends React.PureComponent<RouteComponentProps<string>> {
  render() {
    const { location, children } = this.props;
    return (
      <>
        <NavBarContainer>
          <Logo>
            <CascadeIcon src={cascadeLogo} />
            <CascadeTitle>Cascade</CascadeTitle>
            <SeparatorBar />
          </Logo>
          <NavBarTabs onChange={this.onPageTabChange} selectedPage={location.pathname} />
          <SeparatorBar />
          <SettingsCog src={cog} alt="Settings cog button" onClick={this.handleSettingsClick} />
          <SeparatorBar />
          <Navigate onClick={this.handleNewPassword}>Change password</Navigate>
          <SeparatorBar />
          <Navigate onClick={logout}>Log out</Navigate>
        </NavBarContainer>
        <div style={{ marginTop: '6.2rem' }}>{children}</div>
      </>
    );
  }
  onPageTabChange = (url: string) => {
    history.push(url);
  };
  handleNewPassword = () => {
    history.push(urls.NEW_PASSWORD);
  };

  handleSettingsClick = () => {
    history.push(urls.CONFIGURATION);
  };
}

export default withRouter(PageLayout);
