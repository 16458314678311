import { IChangePasswordValues, ILoginValues } from '@redux/Login/types';
import getAPI, { deleteToken, getHttpRequest } from 'services/networking/request';
import Swagger from 'swagger-client';
import { history } from '../../index';
import { urls } from '../../routes';

export const makeLoginRequest: any = (body: ILoginValues) => {
  const request = {
    url: `/auth/jwt/create`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return Swagger.http(request).then((res: any) => res);
};
export const login = async (data: ILoginValues) => {
  const response = await makeLoginRequest(data);
  return response.body;
};
export const logout = async () => {
  const request = await getHttpRequest('/auth/jwt/logout', 'post');
  Swagger.http(request).then((res: any) => res);
  history.push(urls.LOGIN);
  deleteToken();
};

export const changePassword = async (passwordForm: IChangePasswordValues) => {
  const body = {
    new_password: passwordForm.newPassword,
    current_password: passwordForm.oldPassword,
  };
  const request = await getHttpRequest('/auth/jwt/change-password', 'post', JSON.stringify(body));
  const response = await Swagger.http(request).then((res: any) => res);
  return response.body;
};

export async function getSimulateEnabled(): Promise<boolean> {
  const api = await getAPI();
  const response = await api.api_clients_list();
  return response.body?.[0]?.simulate_enabled ?? false;
}
