import { IconName } from '@blueprintjs/icons';
export interface IIconMapping {
  [key: string]: IconName;
}
const iconMapping: IIconMapping = {
  PE: 'mugshot',
  PL: 'globe',
  PR: 'automatic-updates',
  INFO: 'search-around',
  REP: 'chart',
  ASSET: 'cube',
  ASSET_ADD: 'cube-add',
  ASSET_REMOVE: 'cube-remove',
  THREAT: 'ninja',
  RISK: 'offline',
  HOME: 'globe',
  PROJECT: 'shield',
  INTELLIGENCE: 'social-media',
  ANALYTICS: 'pulse',
  OBJ: 'locate',
  NETWORK: 'graph',
  EXCHANGE: 'exchange',
  SIMULATE: 'send-to-graph',
  SIMULATIONCLIENT: 'mugshot',
  UPLOAD: 'upload',
  FREEFORM: 'settings',
  RDS: 'pulse',
  SHAPESET: 'heat-grid',
  ACCUMULATION: 'layers',
  LOCATE: 'locate',
  DOWNLOAD: 'download',
  CANCEL: 'disable',
  VIEW: 'eye-open',
  HIDE: 'eye-off',
  BOUND: 'book',
  MAP: 'globe',
};

export default iconMapping;
