import { assetDeleteSuccess, assetSaveSuccess } from '@redux/Assets';
import {
  intelligenceSaveError,
  intelligenceSaveRequest,
  intelligenceSaveSuccess,
} from '@redux/Intelligence';
import { getEntitySaveFormData } from '@redux/utils/savingFormData';
import {
  displayDeletingSuccessMessage,
  displayRequestErrorToaster,
  displaySavingSuccessMessage,
} from '@redux/utils/savingToaster';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createIntelligence,
  deleteIntelligence,
  getIntelligenceById,
  getList,
  updateIntelligence,
} from 'services/networking/intelligence';
import { parseError } from 'services/networking/parseError';
import { ActionType, getType } from 'typesafe-actions';
import { history } from '../../index';
import urls from '../../routes/urls';
import {
  intelligenceDeleteError,
  intelligenceDeleteRequest,
  intelligenceDeleteSuccess,
  intelligenceError,
  intelligenceListError,
  intelligenceListRequest,
  intelligenceListSuccess,
  intelligenceRequest,
  intelligenceSuccess,
} from './actions';
import { IIntelligence, IIntelligenceListElement } from './types';

export function* intelligenceListRequestSaga(action: ActionType<typeof intelligenceListRequest>) {
  try {
    const intelligenceList: IIntelligenceListElement[] = yield call(getList, action.payload.params);
    yield put(intelligenceListSuccess({ list: intelligenceList }));
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(intelligenceListError({ errorMessage }));
  }
}

export function* intelligenceListFailedSaga(_: ActionType<typeof intelligenceListError>) {
  yield alert('Error while retrieving the intelligence list');
}

export function* intelligenceRequestSaga(action: ActionType<typeof intelligenceRequest>) {
  try {
    const intelligence: IIntelligence = yield call(
      getIntelligenceById,
      action.payload.intelligenceId,
    );
    yield put(intelligenceSuccess({ intelligence }));
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(intelligenceError({ errorMessage }));
    alert('Error while retrieving the POI');
  }
}

export function* intelligenceSaveRequestSaga(action: ActionType<typeof intelligenceSaveRequest>) {
  let intelligence: IIntelligence;
  const payload = action.payload.intelligence;
  const updated_payload = {
    ...payload,
    tags: payload.tags.map(tag => tag.uuid),
  };
  const data = getEntitySaveFormData(updated_payload, ['files']);
  try {
    if ('uuid' in action.payload.intelligence) {
      intelligence = yield call(updateIntelligence, action.payload.intelligence.uuid, data);
    } else {
      intelligence = yield call(createIntelligence, data);
    }
    yield put(intelligenceSaveSuccess({ intelligence }));
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(intelligenceSaveError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}

export function* intelligenceSaveSuccessSaga(_: ActionType<typeof assetSaveSuccess>) {
  yield history.push(urls.INTELLIGENCE);
  displaySavingSuccessMessage('piece of intelligence');
}

export function* intelligenceDeleteRequestSaga(
  action: ActionType<typeof intelligenceDeleteRequest>,
) {
  try {
    yield call(deleteIntelligence, action.payload.uuid);
    yield put(intelligenceDeleteSuccess());
  } catch (error) {
    const { errorMessage, errorStatus } = parseError(error);
    yield put(intelligenceDeleteError({ errorMessage }));
    displayRequestErrorToaster(errorStatus);
  }
}
export function* intelligenceDeleteSuccessSaga(_: ActionType<typeof assetDeleteSuccess>) {
  yield history.push(urls.INTELLIGENCE);
  displayDeletingSuccessMessage('piece of intelligence');
}

export default function* threatListSaga() {
  yield takeLatest(getType(intelligenceListRequest), intelligenceListRequestSaga);
  yield takeEvery(getType(intelligenceListError), intelligenceListFailedSaga);
  yield takeEvery(getType(intelligenceRequest), intelligenceRequestSaga);
  yield takeEvery(getType(intelligenceSaveRequest), intelligenceSaveRequestSaga);
  yield takeEvery(getType(intelligenceSaveSuccess), intelligenceSaveSuccessSaga);
  yield takeEvery(getType(intelligenceDeleteRequest), intelligenceDeleteRequestSaga);
  yield takeEvery(getType(intelligenceDeleteSuccess), intelligenceDeleteSuccessSaga);
}
