import { Colors } from '@blueprintjs/core';
import styled from 'styled-components';

export const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6.2rem;
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.2), 0 1px 1px rgba(16, 22, 26, 0.4),
    0 2px 6px rgba(16, 22, 26, 0.4);
  background-color: ${Colors.DARK_GRAY4};
  z-index: 10;
  position: fixed;
  width: 100%;
  top: 0;
`;

export const Logo = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
`;
export const CascadeIcon = styled.img`
  height: 65%;
  position: relative;
`;
export const CascadeTitle = styled.h4`
  font-weight: normal;
  margin-left: 1rem;
  margin-right: 2rem;
  color: #e6e0de;
  font-size: 2.2rem;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

export const Navigate = styled.div`
  cursor: pointer;
  height: 100%;
  max-width: 15rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SettingsCog = styled.img`
  padding: 0 1rem;
  cursor: pointer;
  height: 50%;
  display: flex;
  align-items: center;
`;

export const SeparatorBar = styled.div`
  width: 1px;
  height: 45%;
  background-color: ${Colors.GRAY3};
`;
