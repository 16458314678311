import { IMapTileIntelligenceLocation, IPaginated, IPaginationParams } from '@redux/sharedTypes';
import axios from 'axios';
import {
  IIntelligence,
  IIntelligenceListElement,
  IIntelligenceListFilter,
  ITimeGraphData,
  ITimeGraphFilter,
} from 'src/redux/Intelligence/types';
import getAPI, { getAxiosConfig, getAxiosFormDataConfig } from './request';

export async function getList(
  params?: IIntelligenceListFilter & IPaginationParams,
): Promise<IPaginated<IIntelligenceListElement[]>> {
  const url = `/api/intelligence/`;
  const config = await getAxiosConfig(url, 'get', params || {});
  const response = await axios(config);
  return response.data;
}

export async function getIntelligenceById(intelligenceId: string): Promise<IIntelligence> {
  const api = await getAPI();
  const response = await api.api_intelligence_read({ uuid: intelligenceId });
  return response.body;
}

export async function deleteIntelligence(uuid: string): Promise<void> {
  const api = await getAPI();
  await api.api_intelligence_delete({
    uuid,
  });
}

export async function updateIntelligence(uuid: string, payload: FormData): Promise<IIntelligence> {
  const url = `/api/intelligence/${uuid}/`;
  const config = await getAxiosFormDataConfig(url, 'put', payload);
  const response = await axios(config);
  return response.data;
}

export async function createIntelligence(payload: FormData): Promise<IIntelligence> {
  const url = `/api/intelligence/`;
  const config = await getAxiosFormDataConfig(url, 'post', payload);
  const response = await axios(config);
  return response.data;
}

export async function getTimeGraphIntelligence(params: ITimeGraphFilter): Promise<ITimeGraphData> {
  const url = `/api/intelligence/graph/time/`;
  const config = await getAxiosConfig(url, 'get', params);
  const response = await axios(config);
  return response.data;
}

export async function getMapIntelligence(
  params: ITimeGraphFilter,
): Promise<IMapTileIntelligenceLocation[]> {
  const url = `/api/intelligence/map/`;
  const config = await getAxiosConfig(url, 'get', params);
  const response = await axios(config);
  return response.data;
}
