import includes from 'lodash/includes';
import keys from 'lodash/keys';

const isFileOrStringOrBooleanOrEmpty = (element: File | string | boolean) =>
  element instanceof File ||
  typeof element === 'string' ||
  !element ||
  typeof element === 'boolean';

export function getEntitySaveFormData(newEntity: unknown, readOnlyValues: string[]): FormData {
  const data = new FormData();
  for (const attribute of keys(newEntity)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = newEntity[attribute];
    if (Object.hasOwnProperty.call(newEntity, attribute)) {
      if (value === undefined || value === null || includes(readOnlyValues, attribute)) {
        continue;
      }
      if (typeof value === 'number') {
        data.append(attribute, value.toString());
      } else if (isFileOrStringOrBooleanOrEmpty(value)) {
        data.append(attribute, value);
      } else if (Array.isArray(value)) {
        if (isFileOrStringOrBooleanOrEmpty(value[0])) {
          value.forEach((element: File | string) => data.append(attribute, element));
        } else {
          data.append(attribute, JSON.stringify(value));
        }
      } else if (typeof value === 'object') {
        data.append(attribute, JSON.stringify(value));
      }
    }
  }
  return data;
}
