import {
  INewProject,
  INewRiskSerialized,
  IProjectElement,
  IRiskListElement,
} from '@redux/Risks/types';
import { createStandardAction } from 'typesafe-actions';

export const riskListRequest = createStandardAction('Risks/RISK_LIST_REQUEST')();

export const riskListSuccess = createStandardAction('Risks/RISK_LIST_SUCCESS')<{
  list: IRiskListElement[];
}>();

export const riskListError = createStandardAction('Risks/RISK_LIST_ERROR')<{
  errorMessage: string;
}>();

export const riskSaveRequest = createStandardAction('Risks/RISK_SAVE_REQUEST')<{
  risk: INewRiskSerialized;
}>();

export const riskSaveSuccess = createStandardAction('Risks/RISK_SAVE_SUCCESS')<{
  risk: INewRiskSerialized;
}>();

export const riskSaveError = createStandardAction('Risks/RISK_SAVE_ERROR')<{
  errorMessage: string;
}>();

export const riskDeleteRequest = createStandardAction('Risks/RISK_DELETE_REQUEST')<{
  uuid: string;
}>();

export const riskDeleteSuccess = createStandardAction('Risks/RISK_DELETE_SUCCESS')<void>();

export const riskDeleteError = createStandardAction('Risks/RISK_DELETE_ERROR')<{
  errorMessage: string;
}>();

export const projectSaveRequest = createStandardAction('Risks/PROJECT_SAVE_REQUEST')<{
  project: INewProject | IProjectElement;
}>();

export const projectSaveSuccess = createStandardAction('Risks/PROJECT_SAVE_SUCCESS')<{
  project: INewProject | IProjectElement;
}>();

export const projectSaveError = createStandardAction('Risks/PROJECT_SAVE_ERROR')<{
  error: any;
}>();

export const toggleAddRiskOverlay = createStandardAction('Risks/TOGGLE_ADD_RISK_OVERLAY')();

export const setAddProjectOverlay = createStandardAction('Risks/SET_ADD_PROJECT_OVERLAY')<{
  risk: string | null;
}>();

export const saveRisksFilterValue = createStandardAction('Risks/SAVE_RISKS_FILTER_VALUE')<{
  searchValue: string | undefined;
}>();

export default {
  riskListRequest,
  riskListSuccess,
  riskListError,
  riskSaveRequest,
  riskSaveSuccess,
  riskSaveError,
  toggleAddRiskOverlay,
};
