/* eslint-disable complexity */
import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { simulateEnabledError, simulateEnabledRequest, simulateEnabledSuccess } from '.';
import {
  changePasswordError,
  changePasswordRequest,
  loginUserError,
  loginUserRequest,
} from './actions';

export type LoginAction = ActionType<
  | typeof loginUserRequest
  | typeof loginUserError
  | typeof changePasswordError
  | typeof changePasswordRequest
  | typeof simulateEnabledRequest
  | typeof simulateEnabledError
  | typeof simulateEnabledSuccess
>;

export type LoginState = Readonly<{
  loginError: string | null;
  changePasswordError: string | null;
  simulateEnabled: boolean;
}>;

const initialState: LoginState = {
  loginError: null,
  changePasswordError: null,
  simulateEnabled: false,
};

const reducer = (state: LoginState = initialState, action: AnyAction) => {
  const typedAction = action as LoginAction;
  switch (typedAction.type) {
    case getType(loginUserRequest):
      return {
        ...state,
        loginError: '',
      };
    case getType(loginUserError):
      return {
        ...state,
        loginError: typedAction.payload.errorMessage,
      };
    case getType(changePasswordRequest):
      return {
        ...state,
        changePasswordError: '',
      };
    case getType(changePasswordError):
      return {
        ...state,
        changePasswordError: typedAction.payload.errorMessage,
      };
    case getType(simulateEnabledRequest):
    case getType(simulateEnabledError):
      return state;
    case getType(simulateEnabledSuccess):
      return { ...state, simulateEnabled: typedAction.payload.simulateEnabled };
    default:
      return state;
  }
};

export default reducer;
