/* eslint-disable complexity */
import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import {
  geocodingAssetCsvGetRequest,
  geocodingAssetCsvGetSuccess,
  geocodingAssetCsvListRequest,
  geocodingAssetCsvListSuccess,
  geocodingAssetCsvSaveRequest,
  geocodingAssetCsvSaveSuccess,
} from './actions';
import { IGeocodingAssetCsv } from './types';

export type GeocodingAction = ActionType<
  | typeof geocodingAssetCsvSaveRequest
  | typeof geocodingAssetCsvSaveSuccess
  | typeof geocodingAssetCsvGetRequest
  | typeof geocodingAssetCsvGetSuccess
  | typeof geocodingAssetCsvListRequest
  | typeof geocodingAssetCsvListSuccess
>;

export type GeocodingState = Readonly<{
  geocodingAssetCsv: IGeocodingAssetCsv | null;
  geocodingAssetCsvList: IGeocodingAssetCsv[] | null;
}>;

const initialState: GeocodingState = {
  geocodingAssetCsv: null,
  geocodingAssetCsvList: [],
};

const reducer = (state: GeocodingState = initialState, action: AnyAction): GeocodingState => {
  const typedAction = action as GeocodingAction;
  switch (typedAction.type) {
    case getType(geocodingAssetCsvSaveSuccess):
      return { ...state, geocodingAssetCsv: typedAction.payload.geocodingAssetCsv };
    case getType(geocodingAssetCsvGetSuccess):
      return { ...state, geocodingAssetCsv: typedAction.payload.geocodingAssetCsv };
    case getType(geocodingAssetCsvSaveRequest):
    case getType(geocodingAssetCsvGetRequest):
      return { ...state };
    case getType(geocodingAssetCsvListRequest):
      return { ...state };
    case getType(geocodingAssetCsvListSuccess):
      return { ...state, geocodingAssetCsvList: typedAction.payload.geocodingAssetCsvList };
    default:
      return { ...state };
  }
};
export default reducer;
