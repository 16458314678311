import { getEntitySaveFormData } from '@redux/utils/savingFormData';
import {
  displayDeletingSuccessMessage,
  displayRequestErrorToaster,
  displaySavingSuccessMessage,
} from '@redux/utils/savingToaster';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  createAsset,
  deleteAsset,
  getAssetById,
  getList,
  updateAsset,
} from 'services/networking/assets';
import { parseError } from 'services/networking/parseError';
import { ActionType, getType } from 'typesafe-actions';
import { history } from '../../index';
import urls from '../../routes/urls';
import {
  assetBasicSaveRequest,
  assetBasicSaveSuccess,
  assetDeleteError,
  assetDeleteRequest,
  assetDeleteSuccess,
  assetError,
  assetListError,
  assetListRequest,
  assetListSuccess,
  assetRequest,
  assetSaveError,
  assetSaveRequest,
  assetSaveSuccess,
  assetSuccess,
} from './actions';
import { IAsset, IAssetListElement } from './types';

export function* assetListRequestSaga(_: ActionType<typeof assetListRequest>) {
  try {
    const assetList: IAssetListElement[] = yield call(getList);
    yield put(assetListSuccess({ list: assetList }));
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(assetListError({ errorMessage }));
  }
}

export function* assetRequestSaga(action: ActionType<typeof assetRequest>) {
  try {
    const asset: IAsset = yield call(getAssetById, action.payload.assetId);
    yield put(assetSuccess({ asset }));
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(assetError({ errorMessage }));
  }
}

export function* assetSaveRequestSaga(action: ActionType<typeof assetSaveRequest>) {
  try {
    let asset: IAsset;
    const data = getEntitySaveFormData(action.payload.asset, ['files', 'risks']);
    if ('uuid' in action.payload.asset) {
      asset = yield call(updateAsset, action.payload.asset.uuid, data);
    } else {
      asset = yield call(createAsset, data);
    }
    yield put(assetSaveSuccess({ asset }));
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(assetSaveError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}

export function* assetListFailedSaga(_: ActionType<typeof assetListError>) {
  yield alert('Error while retrieving the asset list');
}

export function* assetFailedSaga(_: ActionType<typeof assetError>) {
  yield alert('Error while retrieving the asset');
}

export function* assetSaveSuccessSaga(_: ActionType<typeof assetSaveSuccess>) {
  yield history.push(urls.ASSETS);
  displaySavingSuccessMessage('asset');
}

export function* assetDeleteRequestSaga(action: ActionType<typeof assetDeleteRequest>) {
  try {
    yield call(deleteAsset, action.payload.uuid);
    yield put(assetDeleteSuccess());
  } catch (error) {
    const { errorMessage, errorStatus } = parseError(error);
    yield put(assetDeleteError({ errorMessage }));
    displayRequestErrorToaster(errorStatus);
  }
}
export function* assetDeleteSuccessSaga(_: ActionType<typeof assetDeleteSuccess>) {
  yield history.push(urls.ASSETS);
  displayDeletingSuccessMessage('asset');
}

export function* assetBasicSaveRequestSaga(action: ActionType<typeof assetBasicSaveRequest>) {
  try {
    const data = getEntitySaveFormData(action.payload.asset, ['files', 'risks']);
    const asset: IAsset = yield call(createAsset, data);
    yield put(assetBasicSaveSuccess({ asset }));
    yield call(assetListRequest);
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(assetSaveError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}

export function* assetBasicSaveSuccessSaga(_: ActionType<typeof assetBasicSaveSuccess>) {
  yield put(assetListRequest());
  displaySavingSuccessMessage('asset');
}

export default function* assetListSaga() {
  yield takeLatest(getType(assetListRequest), assetListRequestSaga);
  yield takeEvery(getType(assetListError), assetListFailedSaga);
  yield takeEvery(getType(assetRequest), assetRequestSaga);
  yield takeEvery(getType(assetError), assetFailedSaga);
  yield takeEvery(getType(assetSaveRequest), assetSaveRequestSaga);
  yield takeEvery(getType(assetSaveSuccess), assetSaveSuccessSaga);
  yield takeEvery(getType(assetDeleteRequest), assetDeleteRequestSaga);
  yield takeEvery(getType(assetDeleteSuccess), assetDeleteSuccessSaga);
  yield takeEvery(getType(assetBasicSaveRequest), assetBasicSaveRequestSaga);
  yield takeEvery(getType(assetBasicSaveSuccess), assetBasicSaveSuccessSaga);
}
