import {
  IIntelligence,
  IIntelligenceListElement,
  IIntelligenceListFilter,
  INewIntelligence,
} from '@redux/Intelligence/types';
import { createStandardAction } from 'typesafe-actions';

export const intelligenceListRequest = createStandardAction(
  'Intelligence/INTELLIGENCE_LIST_REQUEST',
)<{
  params?: IIntelligenceListFilter;
}>();

export const intelligenceListSuccess = createStandardAction(
  'Intelligence/INTELLIGENCE_LIST_SUCCESS',
)<{
  list: IIntelligenceListElement[];
}>();

export const intelligenceListError = createStandardAction('Intelligence/INTELLIGENCE_LIST_ERROR')<{
  errorMessage: string;
}>();

export const intelligenceRequest = createStandardAction('Intelligence/INTELLIGENCE_REQUEST')<{
  intelligenceId: string;
}>();

export const intelligenceSuccess = createStandardAction('Intelligence/INTELLIGENCE_SUCCESS')<{
  intelligence: IIntelligence;
}>();

export const intelligenceError = createStandardAction('Intelligence/INTELLIGENCE_ERROR')<{
  errorMessage: string;
}>();

export const intelligenceSaveRequest = createStandardAction(
  'Intelligences/INTELLIGENCE_SAVE_REQUEST',
)<{
  intelligence: IIntelligence | INewIntelligence;
}>();

export const intelligenceSaveSuccess = createStandardAction(
  'Intelligences/INTELLIGENCE_SAVE_SUCCESS',
)<{
  intelligence: IIntelligence | INewIntelligence;
}>();

export const intelligenceSaveError = createStandardAction('Intelligences/INTELLIGENCE_SAVE_ERROR')<{
  errorMessage: string;
}>();

export const intelligenceDeleteRequest = createStandardAction(
  'Intelligences/INTELLIGENCE_DELETE_REQUEST',
)<{
  uuid: string;
}>();

export const intelligenceDeleteSuccess = createStandardAction(
  'Intelligences/INTELLIGENCE_DELETE_SUCCESS',
)<void>();

export const intelligenceDeleteError = createStandardAction(
  'Intelligences/INTELLIGENCE_DELETE_ERROR',
)<{
  errorMessage: string;
}>();

export default {
  intelligenceListRequest,
  intelligenceListSuccess,
  intelligenceListError,
  intelligenceRequest,
  intelligenceSuccess,
  intelligenceError,
  intelligenceSaveRequest,
  intelligenceSaveSuccess,
  intelligenceSaveError,
  intelligenceDeleteRequest,
  intelligenceDeleteSuccess,
  intelligenceDeleteError,
};
