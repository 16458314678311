import map from 'lodash/map';

export interface IGeographyMap {
  label: string;
  icon: string;
}

export const PATH_TO_ICONS = 'static/Geography/';

export const geographyMapping: { [key: string]: IGeographyMap } = {
  global: { label: 'Global', icon: 'Global.svg' },
  europe: { label: 'Europe', icon: 'Europe.svg' },
  asia: { label: 'Asia', icon: 'Asia.svg' },
  africa: { label: 'Africa', icon: 'Africa.svg' },
  americas: { label: 'Americas', icon: 'Americas.svg' },
  middleEast: { label: 'Middle East', icon: 'MiddleEast.svg' },
  australasia: { label: 'Australasia', icon: 'Australasia.svg' },
  westernEurope: { label: 'Western Europe', icon: 'WesternEurope.svg' },
  southernEurope: { label: 'Southern Europe', icon: 'SouthernEurope.svg' },
  northernEurope: { label: 'Northern Europe', icon: 'NorthernEurope.svg' },
  easternEurope: { label: 'Eastern Europe', icon: 'EasternEurope.svg' },
  balticStates: { label: 'Baltic States', icon: 'BalticStates.svg' },
  iberia: { label: 'Iberia', icon: 'Iberia.svg' },
  benelux: { label: 'Benelux', icon: 'Benelux.svg' },
  scandinavia: { label: 'Scandinavia', icon: 'Scandinavia.svg' },
  balkans: { label: 'Balkans', icon: 'Balkans.svg' },
  asiaPacific: { label: 'Asia Pacific', icon: 'AsiaPacific.svg' },
  centralAsia: { label: 'Central Asia', icon: 'CentralAsia.svg' },
  indianSubcontinent: { label: 'Indian Subcontinent', icon: 'IndianSubcontinent.svg' },
  southEastAsia: { label: 'South East Asia', icon: 'SouthEastAsia.svg' },
  northAfrica: { label: 'North Africa', icon: 'NorthAfrica.svg' },
  westAfrica: { label: 'West Africa', icon: 'WestAfrica.svg' },
  centralAfrica: { label: 'Central Africa', icon: 'CentralAfrica.svg' },
  southernAfrica: { label: 'Southern Africa', icon: 'SouthernAfrica.svg' },
  hornOfAfrica: { label: 'Horn of Africa', icon: 'HornOfAfrica.svg' },
  subSaharanAfrica: { label: 'Sub-Saharan Africa', icon: 'SubSaharanAfrica.svg' },
  arabianPeninsula: { label: 'Arabian Peninsula', icon: 'ArabianPeninsula.svg' },
  levant: { label: 'Levant', icon: 'Levant.svg' },
  gulfStates: { label: 'Gulf States', icon: 'GulfStates.svg' },
  pacificIslands: { label: 'Pacific Islands', icon: 'PacificIslands.svg' },
  northAmerica: { label: 'North America', icon: 'NorthAmerica.svg' },
  centralAmerica: { label: 'Central America', icon: 'CentralAmerica.svg' },
  caribbean: { label: 'Caribbean', icon: 'Caribbean.svg' },
  southAmerica: { label: 'South America', icon: 'SouthAmerica.svg' },
  afghanistan: { label: 'Afghanistan', icon: 'CentralAsia.svg' },
  albania: { label: 'Albania', icon: 'Balkans.svg' },
  algeria: { label: 'Algeria', icon: 'NorthAfrica.svg' },
  andorra: { label: 'Andorra', icon: 'Iberia.svg' },
  angola: { label: 'Angola', icon: 'WestAfrica.svg' },
  antigua: { label: 'Antigua', icon: 'Caribbean.svg' },
  argentina: { label: 'Argentina', icon: 'Argentina.svg' },
  armenia: { label: 'Armenia', icon: 'CentralAsia.svg' },
  aruba: { label: 'Aruba', icon: 'Caribbean.svg' },
  australia: { label: 'Australia', icon: 'Australia.svg' },
  austria: { label: 'Austria', icon: 'WesternEurope.svg' },
  azerbaijan: { label: 'Azerbaijan', icon: 'CentralAsia.svg' },
  bahamas: { label: 'Bahamas', icon: 'Caribbean.svg' },
  bahrain: { label: 'Bahrain', icon: 'GulfStates.svg' },
  bangladesh: { label: 'Bangladesh', icon: 'IndianSubcontinent.svg' },
  barbados: { label: 'Barbados', icon: 'Caribbean.svg' },
  belarus: { label: 'Belarus', icon: 'EasternEurope.svg' },
  belgium: { label: 'Belgium', icon: 'Benelux.svg' },
  belize: { label: 'Belize', icon: 'CentralAmerica.svg' },
  benin: { label: 'Benin', icon: 'WestAfrica.svg' },
  bhutan: { label: 'Bhutan', icon: 'IndianSubcontinent.svg' },
  bissau: { label: 'Bissau', icon: 'SubSaharanAfrica.svg' },
  bolivia: { label: 'Bolivia', icon: 'SouthAmerica.svg' },
  bosniaAndHerzegovina: { label: 'Bosnia and Herzegovina', icon: 'EasternEurope.svg' },
  botswana: { label: 'Botswana', icon: 'SouthernAfrica.svg' },
  brazil: { label: 'Brazil', icon: 'Brazil.svg' },
  brunei: { label: 'Brunei', icon: 'SouthEastAsia.svg' },
  bulgaria: { label: 'Bulgaria', icon: 'EasternEurope.svg' },
  burkinaFaso: { label: 'Burkina Faso', icon: 'WestAfrica.svg' },
  burma: { label: 'Burma', icon: 'Burma.svg' },
  burundi: { label: 'Burundi', icon: 'SouthernAfrica.svg' },
  cambodia: { label: 'Cambodia', icon: 'Cambodia.svg' },
  cameroon: { label: 'Cameroon', icon: 'WestAfrica.svg' },
  canada: { label: 'Canada', icon: 'Canada.svg' },
  capeVerde: { label: 'Cape Verde', icon: 'Africa.svg' },
  centralAfricanRepublic: { label: 'Central African Republic', icon: 'SubSaharanAfrica.svg' },
  chad: { label: 'Chad', icon: 'NorthAfrica.svg' },
  chile: { label: 'Chile', icon: 'Argentina.svg' },
  china: { label: 'China', icon: 'China.svg' },
  colombia: { label: 'Colombia', icon: 'Colombia.svg' },
  comoros: { label: 'Comoros', icon: 'Madagascar.svg' },
  democraticRepublicOfTheCongo: {
    label: 'Congo, Democratic Republic of the',
    icon: 'SubSaharanAfrica.svg',
  },
  republicOfTheCongo: { label: 'Congo, Republic of the', icon: 'SubSaharanAfrica.svg' },
  costaRica: { label: 'Costa Rica', icon: 'CentralAmerica.svg' },
  croatia: { label: 'Croatia', icon: 'Balkans.svg' },
  cuba: { label: 'Cuba', icon: 'Caribbean.svg' },
  curacao: { label: 'Curacao', icon: 'Caribbean.svg' },
  cyprus: { label: 'Cyprus', icon: 'Israel.svg' },
  czechRepublic: { label: 'Czech Republic', icon: 'EasternEurope.svg' },
  denmark: { label: 'Denmark', icon: 'Denmark.svg' },
  djibouti: { label: 'Djibouti', icon: 'HornofAfrica.svg' },
  dominica: { label: 'Dominica', icon: 'Caribbean.svg' },
  dominicanRepublic: { label: 'Dominican Republic', icon: 'Caribbean.svg' },
  eastTimor: { label: 'East Timor', icon: 'SouthEastAsia.svg' },
  ecuador: { label: 'Ecuador', icon: 'SouthAmerica.svg' },
  egypt: { label: 'Egypt', icon: 'Egypt.svg' },
  elSalvador: { label: 'El Salvador', icon: 'CentralAmerica.svg' },
  eritrea: { label: 'Eritrea', icon: 'HornofAfrica.svg' },
  estonia: { label: 'Estonia', icon: 'BalticStates.svg' },
  ethiopia: { label: 'Ethiopia', icon: 'HornofAfrica.svg' },
  equatorialGuinea: { label: 'Equatorial Guinea', icon: 'WestAfrica.svg' },
  fiji: { label: 'Fiji', icon: 'PacificIslands.svg' },
  finland: { label: 'Finland', icon: 'Scandinavia.svg' },
  france: { label: 'France', icon: 'France.svg' },
  gabon: { label: 'Gabon', icon: 'WestAfrica.svg' },
  gambia: { label: 'Gambia', icon: 'WestAfrica.svg' },
  georgia: { label: 'Georgia', icon: 'CentralAsia.svg' },
  germany: { label: 'Germany', icon: 'Germany.svg' },
  ghana: { label: 'Ghana', icon: 'WestAfrica.svg' },
  greece: { label: 'Greece', icon: 'Greece.svg' },
  grenada: { label: 'Grenada', icon: 'Caribbean.svg' },
  guatemala: { label: 'Guatemala', icon: 'CentralAmerica.svg' },
  guinea: { label: 'Guinea', icon: 'WestAfrica.svg' },
  guyana: { label: 'Guyana', icon: 'SouthAmerica.svg' },
  haiti: { label: 'Haiti', icon: 'Caribbean.svg' },
  honduras: { label: 'Honduras', icon: 'Caribbean.svg' },
  hungary: { label: 'Hungary', icon: 'EasternEurope.svg' },
  iceland: { label: 'Iceland', icon: 'Iceland.svg' },
  india: { label: 'India', icon: 'India.svg' },
  indonesia: { label: 'Indonesia', icon: 'Indonesia.svg' },
  iran: { label: 'Iran', icon: 'Iran.svg' },
  iraq: { label: 'Iraq', icon: 'Iraq.svg' },
  ireland: { label: 'Ireland', icon: 'Ireland.svg' },
  israel: { label: 'Israel', icon: 'Israel.svg' },
  italy: { label: 'Italy', icon: 'Italy.svg' },
  ivoryCoast: { label: 'Ivory Coast', icon: 'WestAfrica.svg' },
  jamaica: { label: 'Jamaica', icon: 'Caribbean.svg' },
  japan: { label: 'Japan', icon: 'Japan.svg' },
  jordan: { label: 'Jordan', icon: 'Levant.svg' },
  kazakhstan: { label: 'Kazakhstan', icon: 'CentralAsia.svg' },
  kenya: { label: 'Kenya', icon: 'SubSaharanAfrica.svg' },
  kiribati: { label: 'Kiribati', icon: 'PacificIslands.svg' },
  koreaDPRK: { label: 'Korea (DPRK)', icon: 'Korea.svg' },
  koreaROK: { label: 'Korea (ROK)', icon: 'Korea.svg' },
  kosovo: { label: 'Kosovo', icon: 'EasternEurope.svg' },
  kuwait: { label: 'Kuwait', icon: 'GulfStates.svg' },
  kyrgyzstan: { label: 'Kyrgyzstan', icon: 'CentralAsia.svg' },
  laos: { label: 'Laos', icon: 'SouthEastAsia.svg' },
  latvia: { label: 'Latvia', icon: 'BalticStates.svg' },
  lebanon: { label: 'Lebanon', icon: 'Levant.svg' },
  lesotho: { label: 'Lesotho', icon: 'SouthAfrica.svg' },
  liberia: { label: 'Liberia', icon: 'WestAfrica.svg' },
  libya: { label: 'Libya', icon: 'NorthAfrica.svg' },
  liechtenstein: { label: 'Liechtenstein', icon: 'WesternEurope.svg' },
  lithuania: { label: 'Lithuania', icon: 'BalticStates.svg' },
  luxembourg: { label: 'Luxembourg', icon: 'Benelux.svg' },
  macedonia: { label: 'Macedonia', icon: 'EasternEurope.svg' },
  madagascar: { label: 'Madagascar', icon: 'Madagascar.svg' },
  malawi: { label: 'Malawi', icon: 'SouthernAfrica.svg' },
  malaysia: { label: 'Malaysia', icon: 'Malaysia.svg' },
  maldives: { label: 'Maldives', icon: 'IndianSubcontinent.svg' },
  mali: { label: 'Mali', icon: 'NorthAfrica.svg' },
  malta: { label: 'Malta', icon: 'SouthernEurope.svg' },
  marshallIslands: { label: 'Marshall Islands', icon: 'PacificIslands.svg' },
  mauritania: { label: 'Mauritania', icon: 'NorthAfrica.svg' },
  mauritius: { label: 'Mauritius', icon: 'Madagascar.svg' },
  mexico: { label: 'Mexico', icon: 'Mexico.svg' },
  micronesia: { label: 'Micronesia', icon: 'PacificIslands.svg' },
  moldova: { label: 'Moldova', icon: 'EasternEurope.svg' },
  monaco: { label: 'Monaco', icon: 'SouthernEurope.svg' },
  mongolia: { label: 'Mongolia', icon: 'CentralAsia.svg' },
  montenegro: { label: 'Montenegro', icon: 'EasternEurope.svg' },
  morocco: { label: 'Morocco', icon: 'NorthAfrica.svg' },
  mozambique: { label: 'Mozambique', icon: 'SouthernAfrica.svg' },
  namibia: { label: 'Namibia', icon: 'SouthernAfrica.svg' },
  nauru: { label: 'Nauru', icon: 'PacificIslands.svg' },
  nepal: { label: 'Nepal', icon: 'IndianSubcontinent.svg' },
  netherlands: { label: 'Netherlands', icon: 'Benelux.svg' },
  newZealand: { label: 'New Zealand', icon: 'NewZealand.svg' },
  nicaragua: { label: 'Nicaragua', icon: 'CentralAmerica.svg' },
  niger: { label: 'Niger', icon: 'WestAfrica.svg' },
  nigeria: { label: 'Nigeria', icon: 'Nigeria.svg' },
  norway: { label: 'Norway', icon: 'Norway.svg' },
  oman: { label: 'Oman', icon: 'Oman.svg' },
  pakistan: { label: 'Pakistan', icon: 'Pakistan.svg' },
  palau: { label: 'Palau', icon: 'PacificIslands.svg' },
  palestinianTerritories: { label: 'Palestinian Territories', icon: 'Levant.svg' },
  panama: { label: 'Panama', icon: 'CentralAmerica.svg' },
  papuaNewGuinea: { label: 'Papua New Guinea', icon: 'PapuaNewGuinea.svg' },
  paraguay: { label: 'Paraguay', icon: 'Argentina.svg' },
  peru: { label: 'Peru', icon: 'SouthAmerica.svg' },
  philippines: { label: 'Philippines', icon: 'Philippines.svg' },
  poland: { label: 'Poland', icon: 'EasternEurope.svg' },
  portugal: { label: 'Portugal', icon: 'Iberia.svg' },
  qatar: { label: 'Qatar', icon: 'GulfStates.svg' },
  romania: { label: 'Romania', icon: 'EasternEurope.svg' },
  russia: { label: 'Russia', icon: 'Russia.svg' },
  rwanda: { label: 'Rwanda', icon: 'SubSaharanAfrica.svg' },
  saintKittsAndNevis: { label: 'Saint Kitts and Nevis', icon: 'Caribbean.svg' },
  saintLucia: { label: 'Saint Lucia', icon: 'Caribbean.svg' },
  saintVincentAndTheGrenadines: {
    label: 'Saint Vincent and the Grenadines',
    icon: 'Caribbean.svg',
  },
  samoa: { label: 'Samoa', icon: 'PacificIslands.svg' },
  sanMarino: { label: 'San Marino', icon: 'Italy.svg' },
  saoTome: { label: 'Sao Tome', icon: 'WestAfrica.svg' },
  saudiArabia: { label: 'Saudi Arabia', icon: 'SaudiArabia.svg' },
  senegal: { label: 'Senegal', icon: 'WestAfrica.svg' },
  serbia: { label: 'Serbia', icon: 'EasternEurope.svg' },
  seychelles: { label: 'Seychelles', icon: 'SouthernAfrica.svg' },
  sierraLeone: { label: 'Sierra Leone', icon: 'WestAfrica.svg' },
  singapore: { label: 'Singapore', icon: 'Malaysia.svg' },
  sintMaarten: { label: 'Sint Maarten', icon: 'Caribbean.svg' },
  slovakia: { label: 'Slovakia', icon: 'EasternEurope.svg' },
  slovenia: { label: 'Slovenia', icon: 'EasternEurope.svg' },
  solomonIslands: { label: 'Solomon Islands', icon: 'PacificIslands.svg' },
  somalia: { label: 'Somalia', icon: 'Somalia.svg' },
  southAfrica: { label: 'South Africa', icon: 'SouthAfrica.svg' },
  southSudan: { label: 'South Sudan', icon: 'SubSaharanAfrica.svg' },
  spain: { label: 'Spain', icon: 'Spain.svg' },
  sriLanka: { label: 'Sri Lanka', icon: 'IndianSubcontinent.svg' },
  sudan: { label: 'Sudan', icon: 'SubSaharanAfrica.svg' },
  suriname: { label: 'Suriname', icon: 'SouthAmerica.svg' },
  swaziland: { label: 'Swaziland', icon: 'SouthAfrica.svg' },
  sweden: { label: 'Sweden', icon: 'Scandinavia.svg' },
  switzerland: { label: 'Switzerland', icon: 'WesternEurope.svg' },
  syria: { label: 'Syria', icon: 'Syria.svg' },
  saiwan: { label: 'Taiwan', icon: 'Taiwan.svg' },
  tajikistan: { label: 'Tajikistan', icon: 'CentralAsia.svg' },
  tanzania: { label: 'Tanzania', icon: 'SouthernAfrica.svg' },
  thailand: { label: 'Thailand', icon: 'SouthEastAsia.svg' },
  trinidadAndTobago: { label: 'Trinidad & Tobago', icon: 'Caribbean.svg' },
  togo: { label: 'Togo', icon: 'WesternEurope.svg' },
  tonga: { label: 'Tonga', icon: 'PacificIslands.svg' },
  tunisia: { label: 'Tunisia', icon: 'NorthAfrica.svg' },
  turkey: { label: 'Turkey', icon: 'Turkey.svg' },
  turkmenistan: { label: 'Turkmenistan', icon: 'CentralAsia.svg' },
  tuvalu: { label: 'Tuvalu', icon: 'PacificIslands.svg' },
  uganda: { label: 'Uganda', icon: 'SouthernAfrica.svg' },
  ukraine: { label: 'Ukraine', icon: 'EasternEurope.svg' },
  unitedArabEmirates: { label: 'United Arab Emirates', icon: 'UnitedArabEmirates.svg' },
  unitedKingdom: { label: 'United Kingdom', icon: 'UnitedKingdom.svg' },
  unitedStates: { label: 'United States', icon: 'UnitedStates.svg' },
  uruguay: { label: 'Uruguay', icon: 'SouthAmerica.svg' },
  uzbekistan: { label: 'Uzbekistan', icon: 'CentralAsia.svg' },
  vanuatu: { label: 'Vanuatu', icon: 'PacificIslands.svg' },
  vatican: { label: 'Vatican', icon: 'Italy.svg' },
  venezuela: { label: 'Venezuela', icon: 'SouthAmerica.svg' },
  vietnam: { label: 'Vietnam', icon: 'Cambodia.svg' },
  yemen: { label: 'Yemen', icon: 'Yemen.svg' },
  zambia: { label: 'Zambia', icon: 'SouthernAfrica.svg' },
  zimbabwe: { label: 'Zimbabwe', icon: 'SouthernAfrica.svg' },
  washingtonDC: { label: 'Washington DC', icon: 'UnitedStates.svg' },
  alabama: { label: 'Alabama', icon: 'UnitedStates.svg' },
  alaska: { label: 'Alaska', icon: 'UnitedStates.svg' },
  arizona: { label: 'Arizona', icon: 'UnitedStates.svg' },
  arkansas: { label: 'Arkansas', icon: 'UnitedStates.svg' },
  california: { label: 'California', icon: 'UnitedStates.svg' },
  colorado: { label: 'Colorado', icon: 'UnitedStates.svg' },
  connecticut: { label: 'Connecticut', icon: 'UnitedStates.svg' },
  delaware: { label: 'Delaware', icon: 'UnitedStates.svg' },
  kentucky: { label: 'Kentucky', icon: 'UnitedStates.svg' },
  hawaii: { label: 'Hawaii', icon: 'UnitedStates.svg' },
  idaho: { label: 'Idaho', icon: 'UnitedStates.svg' },
  illinois: { label: 'Illinois', icon: 'UnitedStates.svg' },
  indiana: { label: 'Indiana', icon: 'UnitedStates.svg' },
  iowa: { label: 'Iowa', icon: 'UnitedStates.svg' },
  kansas: { label: 'Kansas', icon: 'UnitedStates.svg' },
  louisiana: { label: 'Louisiana', icon: 'UnitedStates.svg' },
  maine: { label: 'Maine', icon: 'UnitedStates.svg' },
  maryland: { label: 'Maryland', icon: 'UnitedStates.svg' },
  massachusetts: { label: 'Massachusetts', icon: 'UnitedStates.svg' },
  michigan: { label: 'Michigan', icon: 'UnitedStates.svg' },
  minnesota: { label: 'Minnesota', icon: 'UnitedStates.svg' },
  mississippi: { label: 'Mississippi', icon: 'UnitedStates.svg' },
  missouri: { label: 'Missouri', icon: 'UnitedStates.svg' },
  montana: { label: 'Montana', icon: 'UnitedStates.svg' },
  nebraska: { label: 'Nebraska', icon: 'UnitedStates.svg' },
  nevada: { label: 'Nevada', icon: 'UnitedStates.svg' },
  newHampshire: { label: 'New Hampshire', icon: 'UnitedStates.svg' },
  newJersey: { label: 'New Jersey', icon: 'UnitedStates.svg' },
  newMexico: { label: 'New Mexico', icon: 'UnitedStates.svg' },
  newYork: { label: 'New York', icon: 'UnitedStates.svg' },
  northCarolina: { label: 'North Carolina', icon: 'UnitedStates.svg' },
  northDakota: { label: 'North Dakota', icon: 'UnitedStates.svg' },
  ohio: { label: 'Ohio', icon: 'UnitedStates.svg' },
  oklahoma: { label: 'Oklahoma', icon: 'UnitedStates.svg' },
  oregon: { label: 'Oregon', icon: 'UnitedStates.svg' },
  pennsylvania: { label: 'Pennsylvania', icon: 'UnitedStates.svg' },
  rhodeIsland: { label: 'Rhode Island', icon: 'UnitedStates.svg' },
  southCarolina: { label: 'South Carolina', icon: 'UnitedStates.svg' },
  southDakota: { label: 'South Dakota', icon: 'UnitedStates.svg' },
  tennessee: { label: 'Tennessee', icon: 'UnitedStates.svg' },
  texas: { label: 'Texas', icon: 'UnitedStates.svg' },
  utah: { label: 'Utah', icon: 'UnitedStates.svg' },
  vermont: { label: 'Vermont', icon: 'UnitedStates.svg' },
  virginia: { label: 'Virginia', icon: 'UnitedStates.svg' },
  washington: { label: 'Washington', icon: 'UnitedStates.svg' },
  westVirginia: { label: 'West Virginia', icon: 'UnitedStates.svg' },
  wisconsin: { label: 'Wisconsin', icon: 'UnitedStates.svg' },
  wyoming: { label: 'Wyoming', icon: 'UnitedStates.svg' },
};

export const geographyMappingForList = map(geographyMapping, (i, k) => ({
  uuid: k,
  title: i.label,
}));
