import { sagas as analyticsSagas } from '@redux/Analytics';
import { sagas as assetSagas } from '@redux/Assets';
import { sagas as configurationSaga } from '@redux/Configuration';
import { sagas as geocodingSagas } from '@redux/Geocoding';
import { sagas as intelligenceSagas } from '@redux/Intelligence';
import { sagas as loginSagas } from '@redux/Login';
import { sagas as networkSaga } from '@redux/Network';
import { sagas as riskSagas } from '@redux/Risks';
import { sagas as simulateSagas } from '@redux/Simulate';
import { sagas as threatSagas } from '@redux/Threats';
import { all } from 'redux-saga/effects';

// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all([
    loginSagas(),
    assetSagas(),
    threatSagas(),
    riskSagas(),
    intelligenceSagas(),
    analyticsSagas(),
    configurationSaga(),
    networkSaga(),
    simulateSagas(),
    geocodingSagas(),
  ]);
}
