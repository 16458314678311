import { Spinner } from '@blueprintjs/core';
import React, { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 45vh;
`;

const LoadingSpinner: FC = () => {
  return (
    <Container>
      <Spinner size={50} />
    </Container>
  );
};
export default LoadingSpinner;
