import {
  displayRequestErrorToaster,
  displaySavingSuccessMessage,
} from '@redux/utils/savingToaster';
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  createNetwork,
  getNetworkById,
  getNetworkList,
  updateNetwork,
} from 'services/networking/network';
import { parseError } from 'services/networking/parseError';
import { ActionType, getType } from 'typesafe-actions';
import { networkError, networkRequest } from '.';
import { history } from '../../index';
import urls from '../../routes/urls';
import {
  networkCreateError,
  networkCreateRequest,
  networkCreateSuccess,
  networkListError,
  networkListRequest,
  networkListSuccess,
  networkSaveError,
  networkSaveRequest,
  networkSaveSuccess,
  networkSuccess,
} from './actions';
import { INetwork, INetworkListElement } from './types';

export function* networkRequestSaga(action: ActionType<typeof networkRequest>) {
  try {
    const network: INetwork = yield call(getNetworkById, action.payload.networkUuid);
    yield put(networkSuccess({ network }));
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(networkError({ errorMessage }));
  }
}

export function* networkFailedSaga(_: ActionType<typeof networkError>) {
  yield alert('Error while retrieving the network');
}

export function* networkListRequestSaga(_: ActionType<typeof networkListRequest>) {
  try {
    const networkList: INetworkListElement[] = yield call(getNetworkList);
    yield put(networkListSuccess({ list: networkList }));
  } catch (error) {
    yield put(networkListError({ errorMessage: error instanceof Error ? error.message : '' }));
  }
}
export function* networkListFailedSaga(_: ActionType<typeof networkListError>) {
  yield alert('Error while retrieving the networks');
}

export function* networkSaveRequestSaga(action: ActionType<typeof networkSaveRequest>) {
  try {
    const network: INetwork = yield call(updateNetwork, action.payload.network);
    yield put(networkSaveSuccess({ network }));
    yield call(action.payload.networkSaved);
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(networkSaveError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}
export function networkSaveSuccessSaga(_: ActionType<typeof networkSaveSuccess>) {
  displaySavingSuccessMessage('network');
}
export function* networkCreateRequestSaga(action: ActionType<typeof networkCreateRequest>) {
  try {
    const network: INetwork = yield call(createNetwork, action.payload.network);
    yield put(networkCreateSuccess({ network }));
    yield put(networkListRequest());
  } catch (error) {
    const { errorMessage, responseStatus } = parseError(error);
    yield put(networkCreateError({ errorMessage }));
    displayRequestErrorToaster(responseStatus);
  }
}

export function* networkCreateSuccessSaga(action: ActionType<typeof networkCreateSuccess>) {
  yield history.push(`${urls.NETWORK}/${action.payload.network.uuid}`);
}

export default function* networkSaga() {
  yield takeEvery(getType(networkRequest), networkRequestSaga);
  yield takeEvery(getType(networkError), networkFailedSaga);
  yield takeEvery(getType(networkListRequest), networkListRequestSaga);
  yield takeEvery(getType(networkListError), networkListFailedSaga);
  yield takeEvery(getType(networkSaveRequest), networkSaveRequestSaga);
  yield takeEvery(getType(networkSaveSuccess), networkSaveSuccessSaga);
  yield takeEvery(getType(networkCreateRequest), networkCreateRequestSaga);
  yield takeEvery(getType(networkCreateSuccess), networkCreateSuccessSaga);
}
