/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
/* eslint-disable max-lines */
import { IVulnerability, IVulnerabilityGroup } from '@redux/Assets/types';
import { IPrimaryTagIntelligence } from '@redux/Intelligence/types';
import { loginUserSuccess } from '@redux/Login';
import { ITactic } from '@redux/Threats/types';
import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import {
  tacticDeleteError,
  tacticDeleteRequest,
  tacticDeleteSuccess,
  tacticListError,
  tacticListRequest,
  tacticListSuccess,
  tacticSaveError,
  tacticSaveRequest,
  tacticSaveSuccess,
  tagDeleteError,
  tagDeletePrimaryClear,
  tagDeletePrimaryError,
  tagDeleteRequest,
  tagDeleteSuccess,
  tagSaveError,
  tagSaveRequest,
  tagSaveSuccess,
  vulnerabilityDeleteError,
  vulnerabilityDeleteRequest,
  vulnerabilityDeleteSuccess,
  vulnerabilityGroupDeleteError,
  vulnerabilityGroupDeleteRequest,
  vulnerabilityGroupDeleteSuccess,
  vulnerabilityGroupListError,
  vulnerabilityGroupListRequest,
  vulnerabilityGroupListSuccess,
  vulnerabilityGroupSaveError,
  vulnerabilityGroupSaveRequest,
  vulnerabilityGroupSaveSuccess,
  vulnerabilityListError,
  vulnerabilityListRequest,
  vulnerabilityListSuccess,
  vulnerabilitySaveError,
  vulnerabilitySaveRequest,
  vulnerabilitySaveSuccess,
} from './actions';

export type ConfigurationAction = ActionType<
  | typeof tagSaveRequest
  | typeof tagSaveSuccess
  | typeof tagSaveError
  | typeof tagDeleteRequest
  | typeof tagDeleteSuccess
  | typeof tagDeleteError
  | typeof tagDeletePrimaryError
  | typeof tagDeletePrimaryClear
  | typeof tacticListRequest
  | typeof tacticListSuccess
  | typeof tacticListError
  | typeof tacticSaveRequest
  | typeof tacticSaveSuccess
  | typeof tacticSaveError
  | typeof tacticDeleteRequest
  | typeof tacticDeleteSuccess
  | typeof tacticDeleteError
  | typeof vulnerabilityListRequest
  | typeof vulnerabilityListSuccess
  | typeof vulnerabilityListError
  | typeof vulnerabilitySaveRequest
  | typeof vulnerabilitySaveSuccess
  | typeof vulnerabilitySaveError
  | typeof vulnerabilityDeleteRequest
  | typeof vulnerabilityDeleteError
  | typeof vulnerabilityDeleteSuccess
  | typeof vulnerabilityGroupListRequest
  | typeof vulnerabilityGroupListSuccess
  | typeof vulnerabilityGroupListError
  | typeof vulnerabilityGroupSaveRequest
  | typeof vulnerabilityGroupSaveError
  | typeof vulnerabilityGroupSaveSuccess
  | typeof vulnerabilityGroupDeleteRequest
  | typeof vulnerabilityGroupDeleteSuccess
  | typeof vulnerabilityGroupDeleteError
  | typeof loginUserSuccess
>;
export type ConfigurationState = Readonly<{
  tagSaving: boolean;
  tagDeleting: boolean;
  tactics: ITactic[];
  tacticsLoading: boolean;
  tacticsErrorMessage: string | null;
  vulnerabilities: IVulnerability[];
  vulnerabilityListErrorMessage: string | null;
  vulnerabilityGroups: IVulnerabilityGroup[];
  vulnerabilitiesLoading: boolean;
  primaryTagIntelligenceList: IPrimaryTagIntelligence[];
  primaryTagErrorModalOpen: boolean;
  vulnerabilityGroupListErrorMessage: string | null;
  vulnerabilityGroupsLoading: boolean;
}>;

const initialState: ConfigurationState = {
  tagSaving: false,
  tagDeleting: false,
  tactics: [],
  tacticsLoading: false,
  tacticsErrorMessage: '',
  vulnerabilities: [],
  vulnerabilityListErrorMessage: '',
  vulnerabilitiesLoading: false,
  primaryTagIntelligenceList: [],
  primaryTagErrorModalOpen: false,
  vulnerabilityGroups: [],
  vulnerabilityGroupListErrorMessage: '',
  vulnerabilityGroupsLoading: false,
};

const reducer = (
  state: ConfigurationState = initialState,
  action: AnyAction,
): ConfigurationState => {
  const typedAction = action as ConfigurationAction;
  switch (typedAction.type) {
    case getType(tagSaveRequest):
      return { ...state, tagSaving: true };
    case getType(tagSaveError):
      return { ...state, tagSaving: false };
    case getType(tagSaveSuccess):
      return {
        ...state,
        tagSaving: false,
      };
    case getType(tagDeleteRequest):
      return { ...state, tagDeleting: true };
    case getType(tagDeleteError):
      return { ...state, tagDeleting: false };
    case getType(tagDeleteSuccess):
      return {
        ...state,
        tagDeleting: false,
      };
    case getType(tagDeletePrimaryError):
      return {
        ...state,
        primaryTagIntelligenceList: typedAction.payload.list,
        primaryTagErrorModalOpen: true,
      };
    case getType(tagDeletePrimaryClear):
      return {
        ...state,
        primaryTagIntelligenceList: initialState.primaryTagIntelligenceList,
        primaryTagErrorModalOpen: initialState.primaryTagErrorModalOpen,
      };
    case getType(tacticListRequest):
      return {
        ...state,
        tacticsLoading: true,
      };
    case getType(tacticListSuccess):
      return {
        ...state,
        tactics: typedAction.payload.list,
        tacticsErrorMessage: initialState.tacticsErrorMessage,
        tacticsLoading: false,
      };
    case getType(tacticListError):
      return {
        ...state,
        tactics: initialState.tactics,
        tacticsErrorMessage: typedAction.payload.errorMessage,
        tacticsLoading: false,
      };
    case getType(tacticSaveSuccess):
      return { ...state, tactics: [...state.tactics, typedAction.payload.tactic] };
    case getType(tacticSaveRequest):
    case getType(tacticSaveError):
      return state;
    case getType(tacticDeleteRequest):
    case getType(tacticDeleteError):
      return state;
    case getType(tacticDeleteSuccess):
      return {
        ...state,
        tactics: state.tactics.filter(tactic => tactic.uuid !== typedAction.payload),
      };
    case getType(vulnerabilityListRequest):
      return {
        ...state,
        vulnerabilitiesLoading: true,
      };
    case getType(vulnerabilityListSuccess):
      return {
        ...state,
        vulnerabilities: typedAction.payload.list,
        vulnerabilityListErrorMessage: initialState.vulnerabilityListErrorMessage,
        vulnerabilitiesLoading: false,
      };
    case getType(vulnerabilityListError):
      return {
        ...state,
        vulnerabilities: initialState.vulnerabilities,
        vulnerabilityListErrorMessage: typedAction.payload.errorMessage,
        vulnerabilitiesLoading: false,
      };
    case getType(vulnerabilitySaveSuccess):
      return {
        ...state,
        vulnerabilities: [...state.vulnerabilities, typedAction.payload.vulnerability],
      };
    case getType(vulnerabilitySaveRequest):
    case getType(vulnerabilitySaveError):
      return state;
    case getType(vulnerabilityDeleteSuccess):
      return {
        ...state,
        vulnerabilities: state.vulnerabilities.filter(
          vulnerability => vulnerability.uuid != typedAction.payload,
        ),
      };
    case getType(vulnerabilityDeleteRequest):
    case getType(vulnerabilityDeleteError):
      return state;
    case getType(vulnerabilityGroupListSuccess):
      return {
        ...state,
        vulnerabilityGroups: typedAction.payload.list,
        vulnerabilityGroupListErrorMessage: initialState.vulnerabilityGroupListErrorMessage,
        vulnerabilityGroupsLoading: false,
      };
    case getType(vulnerabilityGroupListRequest):
      return {
        ...state,
        vulnerabilityGroupsLoading: true,
      };
    case getType(vulnerabilityGroupListError):
      return {
        ...state,
        vulnerabilityGroups: initialState.vulnerabilityGroups,
        vulnerabilityGroupListErrorMessage: typedAction.payload.errorMessage,
        vulnerabilityGroupsLoading: false,
      };
    case getType(vulnerabilityGroupSaveSuccess):
      return {
        ...state,
        vulnerabilityGroups: [...state.vulnerabilityGroups, typedAction.payload.vulnerabilityGroup],
      };
    case getType(vulnerabilityGroupSaveError):
    case getType(vulnerabilityGroupSaveRequest):
      return state;
    case getType(vulnerabilityGroupDeleteSuccess):
      return {
        ...state,
        vulnerabilityGroups: state.vulnerabilityGroups.filter(
          vulnerabilityGroup => vulnerabilityGroup.uuid !== typedAction.payload,
        ),
        vulnerabilities: state.vulnerabilities.filter(
          vulnerability => vulnerability.vulnerability_group.uuid !== typedAction.payload,
        ),
      };
    case getType(vulnerabilityGroupDeleteRequest):
    case getType(vulnerabilityGroupDeleteError):
      return state;
    case getType(loginUserSuccess):
      return initialState;
    default:
      return state;
  }
};
export default reducer;
