/* eslint-disable complexity */
import { IIntelligence, IIntelligenceListElement } from '@redux/Intelligence/types';
import { loginUserSuccess } from '@redux/Login';
import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import {
  intelligenceDeleteError,
  intelligenceDeleteRequest,
  intelligenceDeleteSuccess,
  intelligenceError,
  intelligenceListError,
  intelligenceListRequest,
  intelligenceListSuccess,
  intelligenceRequest,
  intelligenceSaveError,
  intelligenceSaveRequest,
  intelligenceSaveSuccess,
  intelligenceSuccess,
} from './actions';

export type IntelligenceAction = ActionType<
  | typeof intelligenceListRequest
  | typeof intelligenceListSuccess
  | typeof intelligenceListError
  | typeof intelligenceRequest
  | typeof intelligenceSuccess
  | typeof intelligenceError
  | typeof intelligenceSaveRequest
  | typeof intelligenceSaveSuccess
  | typeof intelligenceSaveError
  | typeof intelligenceDeleteRequest
  | typeof intelligenceDeleteSuccess
  | typeof intelligenceDeleteError
  | typeof loginUserSuccess
>;

export type IntelligenceState = Readonly<{
  list: IIntelligenceListElement[];
  intelligenceListErrorMessage: string | null;
  intelligence: IIntelligence | null;
  intelligenceSaving: boolean;
  intelligenceErrorMessage: string | null;
  intelligenceLoading: boolean;
}>;

const initialState: IntelligenceState = {
  list: [],
  intelligenceListErrorMessage: '',
  intelligence: null,
  intelligenceSaving: false,
  intelligenceErrorMessage: null,
  intelligenceLoading: false,
};

const reducer = (state: IntelligenceState = initialState, action: AnyAction): IntelligenceState => {
  const typedAction = action as IntelligenceAction;
  switch (typedAction.type) {
    case getType(intelligenceListSuccess):
      return {
        ...state,
        list: typedAction.payload.list,
        intelligenceListErrorMessage: initialState.intelligenceListErrorMessage,
      };
    case getType(intelligenceListError):
      return {
        ...state,
        list: initialState.list,
        intelligenceListErrorMessage: typedAction.payload.errorMessage,
      };
    case getType(intelligenceRequest):
      return {
        ...state,
        intelligenceLoading: true,
      };
    case getType(intelligenceSuccess):
      return {
        ...state,
        intelligence: typedAction.payload.intelligence,
        intelligenceLoading: false,
      };
    case getType(intelligenceError):
      return {
        ...state,
        intelligence: initialState.intelligence,
        intelligenceErrorMessage: typedAction.payload.errorMessage,
        intelligenceLoading: false,
      };
    case getType(intelligenceDeleteRequest):
    case getType(intelligenceDeleteSuccess):
    case getType(intelligenceDeleteError):
      return state;
    case getType(intelligenceSaveRequest):
      return {
        ...state,
        intelligenceSaving: true,
      };
    case getType(intelligenceSaveSuccess):
    case getType(intelligenceSaveError):
      return {
        ...state,
        intelligenceSaving: false,
      };
    case getType(loginUserSuccess):
      return initialState;
    default:
      return state;
  }
};

export default reducer;
