import { ThreatTableElementType } from '@redux/Threats/types';
import { RootState } from '@redux/types';
import average from 'helperFunctions/Calculations/Average';
import { geographyMapping } from 'helperFunctions/GeographyMapping/GeographyMapping';

export const getThreatList = (store: RootState): ThreatTableElementType[] => {
  return store.threat.list.map(threat => ({
    ...threat,
    threatRating: average(threat.intent_rating, threat.capability_rating),
    geographySvg: geographyMapping[threat.geography].icon,
  }));
};

export const getThreat = (store: RootState) => {
  if (!store.threat.threat) {
    return null;
  }
  return store.threat.threat;
};

export const getThreatLoading = (store: RootState) => store.threat.threatLoading;

export const getThreatSaving = (store: RootState) => store.threat.threatSaving;
