import { IGeocodingAssetCsv } from '@redux/Geocoding/types';
import axios from 'axios';
import { getAxiosConfig, getAxiosFormDataConfig } from './request';

export async function createGeocodingAssetCsv(payload: FormData): Promise<IGeocodingAssetCsv> {
  const url = `/api/geocoding_asset_csvs/`;
  const config = await getAxiosFormDataConfig(url, 'post', payload);
  const response = await axios(config);
  return response.data;
}

export const getGeocodingOutput = async (uuid: string): Promise<IGeocodingAssetCsv> => {
  const url = `/api/geocoding_asset_csvs/${uuid}`;
  const config = await getAxiosConfig(url, 'get');
  const response = await axios(config);
  return response.data;
};

export const getGeocodingCsvList = async (): Promise<IGeocodingAssetCsv[]> => {
  const url = `/api/geocoding_asset_csvs/`;
  const config = await getAxiosConfig(url, 'get');
  const response = await axios(config);
  return response.data;
};
