type Error = {
  message: string;
  status: number;
  response: {
    status: number;
  };
};

export const parseError = <T extends any>(error: unknown) => {
  const errorCast = error as Error;
  return {
    errorMessage: errorCast.message,
    errorStatus: errorCast.status,
    responseStatus: errorCast.response ? errorCast.response.status : 500,
    response: errorCast.response as T,
  };
};
