import { SelectedGeocodingCells } from 'pages/Geocoding/types';
import { createStandardAction } from 'typesafe-actions';
import { IGeocodingAssetCsv, INewGeocodingAssetCsv } from './types';

export const geocodingAssetCsvSaveRequest = createStandardAction(
  'Geocoding/GEOCODING_ASSET_CSV_SAVE_REQUEST',
)<{ geocodingAssetCsv: INewGeocodingAssetCsv; selectedCells: SelectedGeocodingCells }>();

export const geocodingAssetCsvSaveSuccess = createStandardAction(
  'Geocoding/GEOCODING_ASSET_CSV_SAVE_SUCCESS',
)<{ geocodingAssetCsv: IGeocodingAssetCsv }>();

export const geocodingAssetCsvGetRequest = createStandardAction(
  'Geocoding/GEOCODING_ASSET_CSV_GET_REQUEST',
)<{ geocodingAssetCsvUuid: string }>();

export const geocodingAssetCsvGetSuccess = createStandardAction(
  'Geocoding/GEOCODING_ASSET_CSV_GET_SUCCESS',
)<{ geocodingAssetCsv: IGeocodingAssetCsv }>();

export const geocodingAssetCsvListRequest = createStandardAction(
  'Geocoding/GEOCODING_ASSET_CSV_LIST_REQUEST',
)();

export const geocodingAssetCsvListSuccess = createStandardAction(
  'Geocoding/GEOCODING_ASSET_CSV_LIST_SUCCESS',
)<{
  geocodingAssetCsvList: IGeocodingAssetCsv[];
}>();

export default {
  geocodingAssetCsvSaveRequest,
  geocodingAssetCsvSaveSuccess,
  geocodingAssetCsvGetSuccess,
  geocodingAssetCsvGetRequest,
  geocodingAssetCsvListSuccess,
  geocodingAssetCsvListRequest,
};
