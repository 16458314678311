/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { IAsset, IAssetListElement } from '@redux/Assets/types';
import { loginUserSuccess } from '@redux/Login';
import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { assetBasicClear, assetBasicSaveRequest, assetBasicSaveSuccess, assetListRequest } from '.';
import {
  assetDeleteError,
  assetDeleteRequest,
  assetDeleteSuccess,
  assetError,
  assetListError,
  assetListSuccess,
  assetRequest,
  assetSaveError,
  assetSaveRequest,
  assetSaveSuccess,
  assetSuccess,
} from './actions';

export type AssetAction = ActionType<
  | typeof assetListRequest
  | typeof assetListSuccess
  | typeof assetListError
  | typeof assetSuccess
  | typeof assetError
  | typeof assetRequest
  | typeof assetSaveSuccess
  | typeof assetSaveError
  | typeof assetSaveRequest
  | typeof assetDeleteRequest
  | typeof assetDeleteSuccess
  | typeof assetDeleteError
  | typeof assetBasicSaveRequest
  | typeof assetBasicSaveSuccess
  | typeof assetBasicClear
  | typeof loginUserSuccess
>;

export type AssetState = Readonly<{
  list: IAssetListElement[];
  asset: IAsset | null;
  assetListErrorMessage: string | null;
  assetErrorMessage: string | null;
  assetLoading: boolean;
  assetSaving: boolean;
  assetsLoading: boolean;
  assetBasicCreated: boolean;
  assetBasicUuid: string;
}>;

const initialState: AssetState = {
  list: [],
  asset: null,
  assetListErrorMessage: null,
  assetErrorMessage: null,
  assetLoading: false,
  assetSaving: false,
  assetsLoading: false,
  assetBasicCreated: false,
  assetBasicUuid: '',
};

const reducer = (state: AssetState = initialState, action: AnyAction): AssetState => {
  const typedAction = action as AssetAction;
  switch (typedAction.type) {
    case getType(assetRequest):
      return {
        ...state,
        assetLoading: true,
        asset: initialState.asset,
      };
    case getType(assetSuccess):
      return {
        ...state,
        asset: typedAction.payload.asset,
        assetLoading: false,
      };
    case getType(assetError):
      return {
        ...state,
        asset: initialState.asset,
        assetErrorMessage: typedAction.payload.errorMessage,
        assetLoading: false,
      };
    case getType(assetListRequest):
      return {
        ...state,
        assetsLoading: true,
        list: initialState.list,
      };
    case getType(assetListSuccess):
      return {
        ...state,
        asset: initialState.asset,
        assetsLoading: false,
        list: typedAction.payload.list,
      };
    case getType(assetListError):
      return {
        ...state,
        list: initialState.list,
        assetsLoading: false,
        assetListErrorMessage: typedAction.payload.errorMessage,
      };
    case getType(assetBasicSaveRequest):
    case getType(assetSaveRequest):
      return {
        ...state,
        assetSaving: true,
      };
    case getType(assetBasicSaveSuccess):
      return {
        ...state,
        asset: initialState.asset,
        assetBasicUuid: typedAction.payload.asset.uuid,
        assetSaving: false,
        assetBasicCreated: true,
      };
    case getType(assetBasicClear):
      return {
        ...state,
        assetBasicUuid: initialState.assetBasicUuid,
        assetBasicCreated: false,
      };
    case getType(assetSaveSuccess):
      return {
        ...state,
        asset: initialState.asset,
        assetSaving: false,
      };
    case getType(assetSaveError):
      return {
        ...state,
        assetSaving: false,
      };
    case getType(assetDeleteError):
    case getType(assetDeleteRequest):
    case getType(assetDeleteSuccess):
      return state;
    case getType(loginUserSuccess):
      return initialState;
    default:
      return state;
  }
};

export default reducer;
