import { IVulnerability } from '@redux/Assets/types';
import { ITimeGraphData, ITimeGraphFilter } from '@redux/Intelligence/types';
import axios from 'axios';
import {
  IInformationGraphFilter,
  INewProject,
  INewRiskSerialized,
  IProjectElement,
  IRiskListElement,
} from 'src/redux/Risks/types';
import getAPI, { getAxiosConfig } from './request';

export async function getList(): Promise<IRiskListElement[]> {
  const api = await getAPI();
  const response = await api.api_risks_list();
  return response.body;
}

export async function createRisk(risk: INewRiskSerialized): Promise<INewRiskSerialized> {
  const api = await getAPI();
  const response = await api.api_risks_create({
    data: risk,
  });
  return response.body;
}

export async function deleteRisk(uuid: string): Promise<void> {
  const api = await getAPI();
  await api.api_risks_delete({
    uuid,
  });
}

export async function updateProject(project: IProjectElement): Promise<IProjectElement> {
  const api = await getAPI();
  const response = await api.api_projects_update({
    uuid: project.uuid,
    data: project,
  });
  return response.body;
}

export async function createProject(project: INewProject): Promise<INewProject> {
  const api = await getAPI();
  const response = await api.api_projects_create({
    data: project,
  });
  return response.body;
}

export async function getTimeGraphRisk(params: ITimeGraphFilter): Promise<ITimeGraphData> {
  const url = `/api/risks/graph/time/`;
  const config = await getAxiosConfig(url, 'get', params);
  const response = await axios(config);
  return response.data;
}

export async function getMissingVulnerabilites(uuid: string): Promise<IVulnerability[]> {
  const url = `/api/risks/${uuid}/missing-vulnerabilities/`;
  const config = await getAxiosConfig(url, 'get');
  const response = await axios(config);
  return response.data;
}

export async function getInformationGraphRisk(params: IInformationGraphFilter): Promise<number> {
  const url = `/api/risks/graph/information/`;
  const config = await getAxiosConfig(url, 'get', params);
  const response = await axios(config);
  return response.data.value;
}
