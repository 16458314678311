import { Intent, Position, Toaster } from '@blueprintjs/core';

export const FeedbackToaster = Toaster.create({
  position: Position.BOTTOM_RIGHT,
});

type IShowMessageToast = (message: string) => void;

export const showErrorToast: IShowMessageToast = (message: string) =>
  FeedbackToaster.show({
    message,
    intent: Intent.DANGER,
    timeout: 3000,
  });
