import {
  displayDeletingSuccessMessage,
  displayRequestErrorToaster,
  displaySavingSuccessMessage,
} from '@redux/utils/savingToaster';
import { call, put, takeEvery } from 'redux-saga/effects';
import { parseError } from 'services/networking/parseError';
import {
  createThreat,
  deleteThreat,
  getThreatById,
  getThreatList,
  updateThreat,
} from 'services/networking/threats';
import { ActionType, getType } from 'typesafe-actions';
import { history } from '../../index';
import { urls } from '../../routes';
import {
  threatDeleteError,
  threatDeleteRequest,
  threatDeleteSuccess,
  threatError,
  threatListError,
  threatListRequest,
  threatListSuccess,
  threatRequest,
  threatSaveError,
  threatSaveRequest,
  threatSaveSuccess,
  threatSuccess,
} from './actions';
import { IThreat, IThreatListElement } from './types';

export function* threatListRequestSaga(_: ActionType<typeof threatListRequest>) {
  try {
    const threatList: IThreatListElement[] = yield call(getThreatList);
    yield put(threatListSuccess({ list: threatList }));
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(threatListError({ errorMessage }));
  }
}

export function* threatListFailedSaga(_: ActionType<typeof threatListError>) {
  yield alert('Error while retrieving the threat list');
}

export function* threatRequestSaga(action: ActionType<typeof threatRequest>) {
  try {
    const threat: IThreat = yield call(getThreatById, action.payload.threatId);
    yield put(threatSuccess({ threat }));
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(threatError({ errorMessage }));
  }
}

export function* threatFailedSaga(_: ActionType<typeof threatError>) {
  yield alert('Error while retrieving the threat');
}

export function* threatSaveRequestSaga(action: ActionType<typeof threatSaveRequest>) {
  try {
    let threat: IThreat;
    if ('uuid' in action.payload.threat) {
      threat = yield call(updateThreat, action.payload.threat);
    } else {
      threat = yield call(createThreat, action.payload.threat);
    }
    yield put(threatSaveSuccess({ threat }));
  } catch (error) {
    const { errorMessage, errorStatus } = parseError(error);
    yield put(threatSaveError({ errorMessage }));
    displayRequestErrorToaster(errorStatus);
  }
}

export function* threatSaveSuccessSaga(_: ActionType<typeof threatSaveSuccess>) {
  yield history.push(urls.THREATS);
  displaySavingSuccessMessage('threat');
}
export function* threatDeleteRequestSaga(action: ActionType<typeof threatDeleteRequest>) {
  try {
    yield call(deleteThreat, action.payload.threat);
    yield put(threatDeleteSuccess());
  } catch (error) {
    const { errorMessage, errorStatus } = parseError(error);
    yield put(threatDeleteError({ errorMessage }));
    displayRequestErrorToaster(errorStatus);
  }
}

export function* threatDeleteSuccessSaga(_: ActionType<typeof threatSaveSuccess>) {
  yield history.push(urls.THREATS);
  displayDeletingSuccessMessage('threat');
}

export default function* threatListSaga() {
  yield takeEvery(getType(threatListRequest), threatListRequestSaga);
  yield takeEvery(getType(threatListError), threatListFailedSaga);
  yield takeEvery(getType(threatRequest), threatRequestSaga);
  yield takeEvery(getType(threatError), threatFailedSaga);
  yield takeEvery(getType(threatSaveRequest), threatSaveRequestSaga);
  yield takeEvery(getType(threatSaveSuccess), threatSaveSuccessSaga);
  yield takeEvery(getType(threatDeleteRequest), threatDeleteRequestSaga);
  yield takeEvery(getType(threatDeleteSuccess), threatDeleteSuccessSaga);
}
