import { Button, FormGroup, Intent } from '@blueprintjs/core';
import { ILoginValues } from '@redux/Login/types';
import { FastField, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import CustomInput from '../../components/CustomInput';
import LargeLogoPage from '../../components/LargeLogoPage';

interface IProps {
  login: (values: ILoginValues) => void;
}

const ForgotPassword = styled.a`
  color: inherit !important;
  text-align: center;
`;

const initialValues = { username: '', password: '' };
const validationSchema = Yup.object().shape({
  username: Yup.string().email().required('Required'),
  password: Yup.string().required('Required'),
});
export default class LoginPage extends React.PureComponent<IProps> {
  render() {
    return (
      <React.Fragment>
        <LargeLogoPage message={['Welcome back to', ' Cascade.']}>
          <Formik
            initialValues={initialValues}
            onSubmit={this.onFormSubmit}
            validationSchema={validationSchema}
          >
            {props => {
              const { handleSubmit } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <FormGroup>
                    <FastField
                      id="username"
                      name="username"
                      placeholder="Username"
                      component={CustomInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FastField
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Password"
                      component={CustomInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button fill intent={Intent.PRIMARY} type="submit">
                      Login
                    </Button>
                  </FormGroup>
                </form>
              );
            }}
          </Formik>
          <ForgotPassword href="mailto:jake.hernandez@another-day.com?Subject=Password%20reset%20request">
            <p>Forgot credentials?</p>
          </ForgotPassword>
        </LargeLogoPage>
      </React.Fragment>
    );
  }
  onFormSubmit = (values: ILoginValues) => {
    this.props.login(values);
  };
}
