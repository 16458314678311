import { createStandardAction } from 'typesafe-actions';
import { IDashboard, INewDashboardSerialized, INewTileSerialized, ITile } from './types';

export const dashboardListRequest = createStandardAction('Analytics/DASHBOARD_LIST_REQUEST')();
export const dashboardListSuccess = createStandardAction('Analytics/DASHBOARD_LIST_SUCCESS')<{
  list: IDashboard[];
}>();
export const dashboardListError = createStandardAction('Analytics/DASHBOARD_LIST_ERROR')<{
  errorMessage: string;
}>();

export const dashboardSaveRequest = createStandardAction('Analytics/DASHBOARD_SAVE_REQUEST')<{
  dashboard: INewDashboardSerialized;
}>();
export const dashboardSaveSuccess = createStandardAction('Analytics/DASHBOARD_SAVE_SUCCESS')<{
  dashboard: IDashboard;
}>();
export const dashboardSaveError = createStandardAction('Analytics/DASHBOARD_SAVE_ERROR')<{
  errorMessage: string;
}>();

export const tileSaveRequest = createStandardAction('Analytics/TILE_SAVE_REQUEST')<{
  tile: INewTileSerialized;
}>();
export const tileSaveSuccess = createStandardAction('Analytics/TILE_SAVE_SUCCESS')<{
  tile: ITile;
}>();
export const tileSaveError = createStandardAction('Analytics/TILE_SAVE_ERROR')<{
  errorMessage: string;
}>();

export const tileDeleteRequest = createStandardAction('Analytics/TILE_DELETE_REQUEST')<{
  tile: ITile;
}>();
export const tileDeleteSuccess = createStandardAction('Analytics/TILE_DELETE_SUCCESS')<{
  tile: ITile;
}>();
export const tileDeleteError = createStandardAction('Analytics/TILE_DELETE_ERROR')<{
  errorMessage: string;
}>();

export default {
  dashboardSaveRequest,
  dashboardSaveSuccess,
  dashboardSaveError,
  dashboardListRequest,
  dashboardListSuccess,
  dashboardListError,
  tileSaveRequest,
  tileSaveSuccess,
  tileSaveError,
  tileDeleteRequest,
  tileDeleteSuccess,
  tileDeleteError,
};
