import { Intent } from '@blueprintjs/core';
import { FeedbackToaster } from 'services/toaster';

export function displayRequestErrorToaster(status: number, specific400ErrorMessage?: string): void {
  if (status === 400) {
    const message = specific400ErrorMessage
      ? specific400ErrorMessage
      : 'All fields must be completed.';
    FeedbackToaster.show({
      message,
      intent: Intent.DANGER,
      timeout: 3000,
    });
  } else {
    const message = 'Something went wrong. Try submitting again.';
    FeedbackToaster.show({
      message,
      intent: Intent.DANGER,
      timeout: 3000,
    });
  }
}
export function displaySavingSuccessMessage(entity: string): void {
  const message = `The ${entity} was successfully saved.`;
  FeedbackToaster.show({
    message,
    intent: Intent.SUCCESS,
    timeout: 3000,
  });
}
export function displayDeletingSuccessMessage(entity: string): void {
  const message = `The ${entity} was successfully deleted.`;
  FeedbackToaster.show({
    message,
    intent: Intent.SUCCESS,
    timeout: 3000,
  });
}
