/* eslint-disable max-lines */
import { Intent } from '@blueprintjs/core';
import { IPaginated } from '@redux/sharedTypes';
import { getEntitySaveFormData } from '@redux/utils/savingFormData';
import {
  displayDeletingSuccessMessage,
  displayRequestErrorToaster,
} from '@redux/utils/savingToaster';
import { call, debounce, put, select, takeEvery } from 'redux-saga/effects';
import { parseError } from 'services/networking/parseError';
import {
  createSimulationClient,
  createSimulationRun,
  deleteSimulationClient,
  getMapSimulationAssets,
  getMapSimulationShapes,
  getSimulationClient,
  getSimulationClientList,
  getSimulationRealisticDisasterScenarioList,
  getSimulationRun,
  getSimulationShapesetList,
  getUserPerms,
  updateSimulationClient,
} from 'services/networking/simulate';
import { FeedbackToaster, showErrorToast } from 'services/toaster';
import { ActionType, getType } from 'typesafe-actions';
import {
  simuationRunSaveAsync,
  simulationRealisticDisasterScenarioListAsync,
  simulationRunAsync,
} from '.';
import { history } from '../../index';
import urls from '../../routes/urls';
import {
  beginSimulationAssetMapPolling,
  beginSimulationShapesMapPolling,
  simulationAssetMapAsync,
  simulationClientAsync,
  simulationClientDeleteAsync,
  simulationClientListAsync,
  simulationClientSaveAsync,
  simulationShapesMapAsync,
  simulationShapesetListAsync,
  simulationUserPermissionsRequestAsync,
} from './actions';
import { getCurrentSimulationRun, getcurrentSimulationClientID } from './selectors';
import {
  ISimulationClient,
  ISimulationClientListElement,
  ISimulationRealisticDisasterScenario,
  ISimulationRun,
  ISimulationRunListElement,
  ISimulationShapeset,
  IUserPermissions,
} from './types';

export function* simulationClientListRequestSaga() {
  try {
    const simulationClientList: ISimulationClientListElement[] = yield call(
      getSimulationClientList,
    );
    yield put(simulationClientListAsync.success({ simulationClientList }));
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(simulationClientListAsync.failure({ errorMessage }));
  }
}

export function* simulationUserPermissionsRequestSaga() {
  try {
    const simulationUserPermissions: IUserPermissions[] = yield call(getUserPerms);
    yield put(
      simulationUserPermissionsRequestAsync.success({ read_only: simulationUserPermissions }),
    );
  } catch (error) {
    yield put(simulationUserPermissionsRequestAsync.failure());
    const { responseStatus } = parseError(error);
    displayRequestErrorToaster(responseStatus);
  }
}

export function* simulationClientSaveRequestSaga(
  action: ActionType<typeof simulationClientSaveAsync.request>,
) {
  try {
    let simulationClient: ISimulationClient;
    const data = getEntitySaveFormData(action.payload.simulationClient, []);
    if ('uuid' in action.payload.simulationClient) {
      simulationClient = yield call(
        updateSimulationClient,
        action.payload.simulationClient.uuid,
        data,
      );
    } else {
      simulationClient = yield call(createSimulationClient, data);
    }
    yield put(simulationClientSaveAsync.success({ simulationClient }));
  } catch (error) {
    yield put(simulationClientSaveAsync.failure());
    const { responseStatus } = parseError(error);
    displayRequestErrorToaster(responseStatus);
  }
}
export function* simulationClientDeleteRequestSaga(
  action: ActionType<typeof simulationClientDeleteAsync.request>,
) {
  try {
    yield call(deleteSimulationClient, action.payload.uuid);
    yield put(simulationClientDeleteAsync.success());
  } catch (error) {
    const { responseStatus } = parseError(error);
    displayRequestErrorToaster(responseStatus);
  }
}

export function* simulationClientDeleteSuccessSaga() {
  yield history.push(urls.SIMULATE);
  yield displayDeletingSuccessMessage('client');
}

export function* simulationClientSaveSuccessSaga(
  action: ActionType<typeof simulationClientSaveAsync.success>,
) {
  yield history.push(`${urls.SIMULATE}/${action.payload.simulationClient.uuid}`);
  yield put(simulationClientListAsync.request());
}

export function* simulationClientRequestSaga(
  action: ActionType<typeof simulationClientAsync.request>,
) {
  try {
    const simulationClient: ISimulationClient = yield call(
      getSimulationClient,
      action.payload.uuid,
    );
    yield put(simulationClientAsync.success({ simulationClient }));
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(simulationClientAsync.failure({ errorMessage }));
  }
}

export function* simulationRunSaveRequestSaga(
  action: ActionType<typeof simuationRunSaveAsync.request>,
) {
  try {
    const simulationRun: ISimulationRunListElement = yield call(
      createSimulationRun,
      action.payload.simulationRun,
    );
    yield put(simuationRunSaveAsync.success({ simulationRun }));
  } catch (error) {
    const { responseStatus } = parseError(error);
    displayRequestErrorToaster(responseStatus);
  }
}

export function simulationRunSaveSuccessSaga() {
  FeedbackToaster.show({
    message: 'Running simulation...',
    intent: Intent.SUCCESS,
    timeout: 3000,
  });
}

export function* simulationRunRequestSaga(action: ActionType<typeof simulationRunAsync.request>) {
  try {
    const simulationRun: ISimulationRun = yield call(getSimulationRun, action.payload.uuid);
    yield put(simulationRunAsync.success({ simulationRun }));
  } catch (error) {
    const { responseStatus } = parseError(error);
    displayRequestErrorToaster(responseStatus);
  }
}

export function* simulationRealisticDisasterScenarioListRequestSaga() {
  try {
    const simulationRealisticDisasterScenarioList: ISimulationRealisticDisasterScenario[] =
      yield call(getSimulationRealisticDisasterScenarioList);
    yield put(
      simulationRealisticDisasterScenarioListAsync.success({
        simulationRealisticDisasterScenarioList,
      }),
    );
  } catch (error) {
    const { responseStatus } = parseError(error);
    displayRequestErrorToaster(responseStatus);
  }
}

export function* simulationShapesetListRequestSaga() {
  try {
    const simulationShapesetList: ISimulationShapeset[] = yield call(getSimulationShapesetList);
    yield put(simulationShapesetListAsync.success({ simulationShapesetList }));
  } catch (error) {
    const { responseStatus } = parseError(error);
    displayRequestErrorToaster(responseStatus);
  }
}

function* simulationAssetMapRequestSaga(
  action: ActionType<typeof simulationAssetMapAsync.request>,
) {
  try {
    const { next, results: geojson }: IPaginated<string> = yield call(
      getMapSimulationAssets,
      action.payload.simulationClient,
      action.payload.page,
    );
    const isNextPage = next !== null;

    yield put(
      simulationAssetMapAsync.success({
        geojson: JSON.parse(geojson),
        clientId: action.payload.simulationClient,
        largeAssetPolling: isNextPage ? true : false,
      }),
    );

    const currentSelectedClient: string | null = yield select(getcurrentSimulationClientID);
    const responseIsCurrentlySelectedClient =
      currentSelectedClient === action.payload.simulationClient;

    if (isNextPage && responseIsCurrentlySelectedClient) {
      yield put(
        simulationAssetMapAsync.request({
          simulationClient: action.payload.simulationClient,
          page: action.payload.page + 1,
        }),
      );
    }
  } catch (error) {
    showErrorToast('Failed to get map assets.');
  }
}

function* simulationAssetMapPollingSaga(action: ActionType<typeof beginSimulationAssetMapPolling>) {
  try {
    yield put(
      simulationAssetMapAsync.request({
        simulationClient: action.payload.simulationClient,
        page: 1,
      }),
    );
  } catch (error) {
    showErrorToast('Failed to get map shapes.');
  }
}

function* simulationShapeMapRequestSaga(
  action: ActionType<typeof simulationShapesMapAsync.request>,
) {
  try {
    const { next, results: geojson }: IPaginated<string> = yield call(
      getMapSimulationShapes,
      action.payload.shapesetId,
      action.payload.page,
    );
    const isNextPage = next !== null;

    yield put(
      simulationShapesMapAsync.success({
        geojson: JSON.parse(geojson),
        largeAssetPolling: isNextPage,
        simulationRunUUID: action.payload.simulationRunUUID,
      }),
    );

    const currentSelectedSimulationRun: ISimulationRun = yield select(getCurrentSimulationRun);
    const responseIsCurrentlySelectedSimulationRun =
      currentSelectedSimulationRun.uuid === action.payload.simulationRunUUID;

    if (isNextPage && responseIsCurrentlySelectedSimulationRun) {
      yield put(
        simulationShapesMapAsync.request({
          shapesetId: action.payload.shapesetId,
          simulationRunUUID: action.payload.simulationRunUUID,
          page: action.payload.page + 1,
        }),
      );
    }
  } catch (error) {
    showErrorToast('Failed to get map.');
  }
}

function* simulationShapeMapPollingSaga(
  action: ActionType<typeof beginSimulationShapesMapPolling>,
) {
  try {
    yield put(
      simulationShapesMapAsync.request({
        shapesetId: action.payload.shapesetId,
        simulationRunUUID: action.payload.simulationRunUUID,
        page: 1,
      }),
    );
  } catch (error) {
    showErrorToast('Failed to get map assets.');
  }
}

export default function* simulateSaga() {
  yield takeEvery(getType(simulationClientListAsync.request), simulationClientListRequestSaga);
  yield takeEvery(getType(simulationClientSaveAsync.request), simulationClientSaveRequestSaga);
  yield takeEvery(getType(simulationClientSaveAsync.success), simulationClientSaveSuccessSaga);
  yield takeEvery(getType(simuationRunSaveAsync.request), simulationRunSaveRequestSaga);
  yield takeEvery(getType(simuationRunSaveAsync.success), simulationRunSaveSuccessSaga);
  yield takeEvery(getType(simulationRunAsync.request), simulationRunRequestSaga);
  yield debounce(1000, getType(simulationAssetMapAsync.request), simulationAssetMapRequestSaga);
  yield takeEvery(getType(simulationClientDeleteAsync.request), simulationClientDeleteRequestSaga);
  yield takeEvery(getType(simulationClientDeleteAsync.success), simulationClientDeleteSuccessSaga);
  yield takeEvery(
    getType(simulationRealisticDisasterScenarioListAsync.request),
    simulationRealisticDisasterScenarioListRequestSaga,
  );
  yield takeEvery(getType(simulationShapesetListAsync.request), simulationShapesetListRequestSaga);
  yield takeEvery(getType(simulationClientAsync.request), simulationClientRequestSaga);
  yield takeEvery(getType(beginSimulationAssetMapPolling), simulationAssetMapPollingSaga);
  yield takeEvery(getType(beginSimulationShapesMapPolling), simulationShapeMapPollingSaga);
  yield debounce(1000, getType(simulationShapesMapAsync.request), simulationShapeMapRequestSaga);
  yield takeEvery(
    getType(simulationUserPermissionsRequestAsync.request),
    simulationUserPermissionsRequestSaga,
  );
}
