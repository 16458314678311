import { getSimulateEnabled } from '@redux/Login';
import LoadingSpinner from 'components/LoadingSpinner';
import PageLayout from 'components/PageLayout';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import Login from '../pages/Login';
import urls from './urls';

const NewPassword = lazy(() => import('../pages/NewPassword'));
const EditAsset = lazy(() => import('../pages/Asset/EditAsset'));
const NewAsset = lazy(() => import('../pages/Asset'));
const Assets = lazy(() => import('../pages/Assets'));
const NewThreat = lazy(() => import('../pages/Threat'));
const EditThreat = lazy(() => import('../pages/Threat/EditThreat'));
const Threats = lazy(() => import('../pages/Threats'));
const Risks = lazy(() => import('../pages/Risks'));
const IntelligenceList = lazy(() => import('../pages/IntelligenceList'));
const NewPieceOfIntelligence = lazy(() => import('../pages/PieceOfIntelligence/'));
const EditPieceOfIntelligence = lazy(
  () => import('../pages/PieceOfIntelligence/EditPieceOfIntelligence'),
);
const Analytics = lazy(() => import('../pages/Analytics'));
const Dashboard = lazy(() => import('../pages/Analytics/components/Dashboard/Dashboard.wrap'));
const Configuration = lazy(() => import('../pages/Configuration/Configuration'));
const Network = lazy(() => import('../pages/Network/Network'));
const Home = lazy(() => import('../pages/Home/Home'));
const Simulate = lazy(() => import('../pages/Simulate/Simulate'));
const SimulationClient = lazy(() => import('../pages/SimulationClient/AddClient/SimulationClient'));
const EditSimulationClient = lazy(
  () => import('../pages/SimulationClient/EditClient/EditSimulationClient'),
);
const SimulationResult = lazy(() => import('../pages/Simulate/SimulationResult'));
const SimulationPage = lazy(() => import('../pages/Simulate/SimulationPage'));
const CsvValidator = lazy(() => import('../pages/CsvValidator/CsvValidator'));
const GeocodingWizard = lazy(() => import('../pages/Geocoding/GeocodingWizard'));

const routes = () => {
  const simulateEnabled = useSelector(getSimulateEnabled);
  return (
    <Switch>
      <Route exact path={urls.LOGIN} component={Login} />
      <PageLayout>
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            <Route exact path={urls.NEW_PASSWORD} component={NewPassword} />
            <Route exact path={`${urls.ASSETS}/new`} component={NewAsset} />
            <Route exact path={`${urls.ASSETS}/:id`} component={EditAsset} />
            <Route exact path={urls.ASSETS} component={Assets} />
            <Route exact path={`${urls.THREATS}/new`} component={NewThreat} />
            <Route exact path={`${urls.THREATS}/:id`} component={EditThreat} />
            <Route exact path={`${urls.THREATS}`} component={Threats} />
            <Route exact path={`${urls.RISKS}`} component={Risks} />
            <Route exact path={`${urls.INTELLIGENCE}`} component={IntelligenceList} />
            <Route exact path={`${urls.INTELLIGENCE}/new`} component={NewPieceOfIntelligence} />
            <Route exact path={`${urls.INTELLIGENCE}/:id`} component={EditPieceOfIntelligence} />
            <Route exact path={`${urls.ANALYTICS}`} component={Analytics} />
            <Route exact path={`${urls.ANALYTICS}/dashboard/:id`} component={Dashboard} />
            <Route exact path={urls.CONFIGURATION} component={Configuration} />
            <Route exact path={urls.NETWORK} component={Network} />
            <Route exact path={`${urls.NETWORK}/:id`} component={Network} />
            <Route exact path={urls.HOME} component={Home} />
            <Route exact path={'/csvvalidator'} component={CsvValidator} />
            {simulateEnabled ? (
              <Switch>
                <Route exact path={urls.SIMULATE} component={Simulate} />
                <Route exact path={`${urls.SIMULATE}/geocoding`} component={GeocodingWizard} />
                <Route exact path={`${urls.SIMULATE}/:id`} component={SimulationPage} />
                <Route exact path={`${urls.SIMULATE}/client/new`} component={SimulationClient} />
                <Route
                  exact
                  path={`${urls.SIMULATE}/client/:id`}
                  component={EditSimulationClient}
                />
                <Route exact path={`${urls.SIMULATE}/run/:id`} component={SimulationResult} />
              </Switch>
            ) : null}
          </Switch>
        </Suspense>
      </PageLayout>
    </Switch>
  );
};

export default routes;
