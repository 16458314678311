/* eslint-disable complexity */
import { loginUserSuccess } from '@redux/Login';
import { IThreat, IThreatListElement } from '@redux/Threats/types';
import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import {
  threatDeleteError,
  threatDeleteRequest,
  threatDeleteSuccess,
  threatError,
  threatListError,
  threatListSuccess,
  threatRequest,
  threatSaveError,
  threatSaveRequest,
  threatSaveSuccess,
  threatSuccess,
} from './actions';

export type ThreatAction = ActionType<
  | typeof threatListSuccess
  | typeof threatListError
  | typeof threatRequest
  | typeof threatSuccess
  | typeof threatError
  | typeof threatSaveError
  | typeof threatSaveRequest
  | typeof threatSaveSuccess
  | typeof threatDeleteRequest
  | typeof threatDeleteSuccess
  | typeof threatDeleteError
  | typeof loginUserSuccess
>;

export type ThreatState = Readonly<{
  list: IThreatListElement[];
  threatListErrorMessage: string | null;
  threatErrorMessage: string | null;
  threat: IThreat | null;
  threatLoading: boolean;
  threatSaving: boolean;
}>;

const initialState: ThreatState = {
  list: [],
  threatListErrorMessage: '',
  threat: null,
  threatLoading: false,
  threatErrorMessage: '',
  threatSaving: false,
};

const reducer = (state: ThreatState = initialState, action: AnyAction): ThreatState => {
  const typedAction = action as ThreatAction;
  switch (typedAction.type) {
    case getType(threatListSuccess):
      return {
        ...state,
        list: typedAction.payload.list,
      };
    case getType(threatListError):
      return {
        ...state,
        list: initialState.list,
        threatListErrorMessage: typedAction.payload.errorMessage,
      };
    case getType(threatRequest):
      return {
        ...state,
        threatLoading: true,
      };
    case getType(threatSuccess):
      return {
        ...state,
        threat: typedAction.payload.threat,
        threatLoading: false,
      };
    case getType(threatError):
      return {
        ...state,
        threat: initialState.threat,
        threatErrorMessage: typedAction.payload.errorMessage,
        threatLoading: false,
      };
    case getType(threatSaveRequest):
      return {
        ...state,
        threatSaving: true,
      };
    case getType(threatSaveSuccess):
    case getType(threatSaveError):
      return {
        ...state,
        threatSaving: false,
      };
    case getType(threatDeleteRequest):
    case getType(threatDeleteSuccess):
      return state;
    case getType(loginUserSuccess):
      return initialState;
    default:
      return state;
  }
};

export default reducer;
