import { Icon, Tab } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import { getSimulateEnabled } from '@redux/Login';
import iconMapping from 'components/IconMapping';
import { map } from 'lodash';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import urls from '../../routes/urls';
import { CustomTabs, TabTitleContainer, TextTitleContainer } from './NavBarTabs.styles';

interface INavBarTabType {
  name: string;
  link: string;
  icon: IconName;
}

const pageTabs: { [name: string]: INavBarTabType } = {
  home: {
    name: 'Home',
    link: urls.HOME,
    icon: iconMapping.PL,
  },
  intelligence: {
    name: 'Intelligence',
    link: urls.INTELLIGENCE,
    icon: iconMapping.INTELLIGENCE,
  },
  threats: {
    name: 'Threats',
    link: urls.THREATS,
    icon: iconMapping.THREAT,
  },
  assets: {
    name: 'Assets',
    link: urls.ASSETS,
    icon: iconMapping.ASSET,
  },
  risks: {
    name: 'Risks',
    link: urls.RISKS,
    icon: iconMapping.RISK,
  },
  analytics: {
    name: 'Analytics',
    link: urls.ANALYTICS,
    icon: iconMapping.ANALYTICS,
  },
  network: {
    name: 'Network',
    link: urls.NETWORK,
    icon: iconMapping.NETWORK,
  },
};
const pageTabsWithSimulate: { [name: string]: INavBarTabType } = {
  ...pageTabs,
  simulate: {
    name: 'Simulate',
    link: urls.SIMULATE,
    icon: iconMapping.SIMULATE,
  },
};

interface INavBarTabsProps {
  selectedPage: string;
  onChange: (url: string) => void;
}

const NavBarTabs: FC<INavBarTabsProps> = props => {
  const { selectedPage, onChange } = props;
  const selectedTab = `/${selectedPage.split('/')[1]}`;
  const simulateEnabled = useSelector(getSimulateEnabled);

  return (
    <CustomTabs id="page-tabs" onChange={onChange} selectedTabId={selectedTab} animate={false}>
      {map(simulateEnabled ? pageTabsWithSimulate : pageTabs, page => (
        <Tab id={page.link} key={page.link}>
          <TabTitleContainer>
            <Icon icon={page.icon} iconSize={20} />
            <TextTitleContainer>{page.name}</TextTitleContainer>
          </TabTitleContainer>
        </Tab>
      ))}
    </CustomTabs>
  );
};
export default NavBarTabs;
