import React from 'react';

// using node-style package resolution in a CSS file
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import 'normalize.css';
import './static/styles/global.css';

import { FocusStyleManager, Popover } from '@blueprintjs/core';
import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { Store } from 'redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

// Fix blurry popover text on windows (https://github.com/palantir/blueprint/issues/394)
Popover.defaultProps.modifiers = { computeStyle: { gpuAcceleration: false } };

import Root from './components/Root';
import Routes from './routes';
interface IProps {
  history: History;
  persistor: Persistor;
  store: Store;
}

const RootComponentWithRoutes: React.SFC = () => (
  <Root>
    <Routes />
  </Root>
);

export default class App extends React.PureComponent<IProps> {
  constructor(props: IProps) {
    super(props);
    FocusStyleManager.onlyShowFocusOnTabs();
  }
  render() {
    document.body.className = 'bp3-dark';
    const { history, persistor, store } = this.props;
    return (
      <React.Fragment>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ConnectedRouter history={history}>
              <Route path="/" component={RootComponentWithRoutes} />
            </ConnectedRouter>
          </PersistGate>
        </Provider>
      </React.Fragment>
    );
  }
}
