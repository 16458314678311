import { INewThreat, IThreat, IThreatListElement } from 'src/redux/Threats/types';
import getAPI from './request';

export async function getThreatList(): Promise<IThreatListElement[]> {
  const api = await getAPI();
  const response = await api.api_threats_list();
  return response.body;
}

export async function getThreatById(threatId: string): Promise<IThreat> {
  const api = await getAPI();
  const response = await api.api_threats_read({ uuid: threatId });
  return response.body;
}

export async function updateThreat(threat: IThreat): Promise<IThreat> {
  const api = await getAPI();
  const response = await api.api_threats_update({
    uuid: threat.uuid,
    data: threat,
  });
  return response.body;
}
export async function createThreat(threat: INewThreat): Promise<IThreat> {
  const api = await getAPI();
  const response = await api.api_threats_create({
    data: threat,
  });
  return response.body;
}
export async function deleteThreat(threat: IThreat): Promise<void> {
  const api = await getAPI();
  await api.api_threats_delete({
    uuid: threat.uuid,
  });
}
