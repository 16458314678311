import React, { ReactNode } from 'react';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';

import { flattenMessages } from 'services/i18n/intl';
import enMessages from 'translations/en.json';
import frMessages from 'translations/fr.json';

const locales = {
  fr: flattenMessages(frMessages),
  en: flattenMessages(enMessages),
};

addLocaleData([...fr, ...en]);

interface IProps {
  children: ReactNode;
}

const Root: React.SFC<IProps> = ({ children }) => (
  <IntlProvider locale="en" messages={locales.en}>
    {children}
  </IntlProvider>
);

export default Root;
