import {
  FeatureCollection,
  ISimulationRun,
  SIMULATION_TYPES,
  ShapeGeoJson,
} from '@redux/Simulate/types';

export const mergeSimulutionShapesetRunJSON = (
  state: ISimulationRun | null,
  geojson: ShapeGeoJson,
) => {
  if (state?.simulation_type != SIMULATION_TYPES.SHAPESET) {
    return state;
  }

  return {
    ...state,
    shapeset: {
      ...state.shapeset,
      shapes: mergeShapesetGeoJsonFeatures(state.shapeset.shapes, geojson),
    },
  };
};

export const mergeGeoJsonFeatures = (
  geo1: FeatureCollection | null,
  geo2: FeatureCollection,
): FeatureCollection => {
  if (geo1 === null) return { ...geo2 };

  return {
    ...geo1,
    features: [...(geo1?.features ?? []), ...(geo2?.features ?? [])],
  };
};

export const mergeShapesetGeoJsonFeatures = (
  existingShapes: ShapeGeoJson,
  newShapes: ShapeGeoJson,
): ShapeGeoJson => {
  return {
    ...existingShapes,
    features: [...(existingShapes?.features ?? []), ...(newShapes?.features ?? [])],
  };
};
