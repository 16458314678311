import { createStandardAction } from 'typesafe-actions';
import { INetwork, INetworkListElement, INewNetworkSerialized } from './types';

export const networkRequest =
  createStandardAction('Network/NETWORK_REQUEST')<{ networkUuid: string }>();
export const networkSuccess =
  createStandardAction('Network/NETWORK_SUCCESS')<{ network: INetwork }>();
export const networkError =
  createStandardAction('Network/NETWORK_ERROR')<{ errorMessage: string }>();
export const networkListRequest = createStandardAction('Network/NETWORK_LIST_REQUEST')();
export const networkListSuccess = createStandardAction('Network/NETWORK_LIST_SUCCESS')<{
  list: INetworkListElement[];
}>();
export const networkListError = createStandardAction('Network/NETWORK_LIST_ERROR')<{
  errorMessage: string;
}>();

export const networkSaveRequest = createStandardAction('Network/NETWORK_SAVE_REQUEST')<{
  network: INetwork;
  networkSaved: () => void;
}>();
export const networkSaveSuccess = createStandardAction('Network/NETWORK_SAVE_SUCCESS')<{
  network: INetwork;
}>();
export const networkSaveError = createStandardAction('Network/NETWORK_SAVE_ERROR')<{
  errorMessage: string;
}>();
export const networkCreateRequest = createStandardAction('Network/NETWORK_CREATE_REQUEST')<{
  network: INewNetworkSerialized;
}>();
export const networkCreateSuccess = createStandardAction('Network/NETWORK_CREATE_SUCCESS')<{
  network: INetwork;
}>();
export const networkCreateError = createStandardAction('Network/NETWORK_CREATE_ERROR')<{
  errorMessage: string;
}>();

export default {
  networkSaveRequest,
  networkSaveSuccess,
  networkSaveError,
  networkListRequest,
  networkListSuccess,
  networkListError,
  networkCreateRequest,
  networkCreateSuccess,
  networkCreateError,
};
