import { AssetTableElementType } from '@redux/Assets/types';
import { riskToRiskTableElement } from '@redux/Risks/selectors';
import { RootState } from '@redux/types';

export const getAssetList = (store: RootState) => store.asset.list;

export const getAsset = (store: RootState) => {
  if (!store.asset.asset) {
    return null;
  }
  return {
    ...store.asset.asset,
    new_files: [],
    risks: store.asset.asset.risks.map(risk => riskToRiskTableElement(risk)),
  };
};

export const getAssetLoading = (store: RootState) => store.asset.assetLoading;

export const getAssetsLoading = (store: RootState) => store.asset.assetsLoading;

export const getAssetSaving = (store: RootState) => store.asset.assetSaving;

export const getAssetBasicCreated = (store: RootState) => store.asset.assetBasicCreated;

export const getAssetBasicUuid = (store: RootState) => store.asset.assetBasicUuid;

export const getAssets = (store: RootState): AssetTableElementType[] => {
  const assetList = getAssetList(store);
  return assetList.map(asset => ({
    ...asset,
    geolocated: !!asset.location,
    totalValue: asset.direct_value + asset.indirect_value,
  }));
};
