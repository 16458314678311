import { INewThreat, IThreat, IThreatListElement } from '@redux/Threats/types';
import { createStandardAction } from 'typesafe-actions';

export const threatListRequest = createStandardAction('Threats/THREAT_LIST_REQUEST')();

export const threatListSuccess = createStandardAction('Threats/THREAT_LIST_SUCCESS')<{
  list: IThreatListElement[];
}>();

export const threatListError = createStandardAction('Threats/THREAT_LIST_ERROR')<{
  errorMessage: string;
}>();

export const threatRequest = createStandardAction('Threats/THREAT_REQUEST')<{
  threatId: string;
}>();

export const threatSuccess = createStandardAction('Threats/THREAT_SUCCESS')<{
  threat: IThreat;
}>();

export const threatError = createStandardAction('Threats/THREAT_ERROR')<{
  errorMessage: string;
}>();

export const threatSaveRequest = createStandardAction('Threats/THREAT_SAVE_REQUEST')<{
  threat: IThreat | INewThreat;
}>();

export const threatSaveSuccess = createStandardAction('Threats/THREAT_SAVE_SUCCESS')<{
  threat: IThreat | INewThreat;
}>();

export const threatSaveError = createStandardAction('Threats/THREAT_SAVE_ERROR')<{
  errorMessage: string;
}>();
export const threatDeleteRequest = createStandardAction('Threats/THREAT_DELETE_REQUEST')<{
  threat: IThreat;
}>();

export const threatDeleteSuccess = createStandardAction('Threats/THREAT_DELETE_SUCCESS')<void>();

export const threatDeleteError = createStandardAction('Threats/THREAT_DELETE_ERROR')<{
  errorMessage: string;
}>();

export default {
  threatListRequest,
  threatListSuccess,
  threatListError,
  threatRequest,
  threatSuccess,
  threatError,
  threatSaveRequest,
  threatSaveSuccess,
  threatSaveError,
  threatDeleteRequest,
  threatDeleteSuccess,
  threatDeleteError,
};
