import {
  displayDeletingSuccessMessage,
  displayRequestErrorToaster,
  displaySavingSuccessMessage,
} from '@redux/utils/savingToaster';
import { call, put, takeEvery } from 'redux-saga/effects';
import { parseError } from 'services/networking/parseError';
import {
  createProject,
  createRisk,
  deleteRisk,
  getList,
  updateProject,
} from 'services/networking/risks';
import { ActionType, getType } from 'typesafe-actions';
import {
  projectSaveError,
  projectSaveRequest,
  projectSaveSuccess,
  riskDeleteError,
  riskDeleteRequest,
  riskDeleteSuccess,
  riskListError,
  riskListRequest,
  riskListSuccess,
  riskSaveError,
  riskSaveRequest,
  riskSaveSuccess,
} from './actions';
import { INewRiskSerialized, IProjectElement, IRiskListElement } from './types';

export function* riskListRequestSaga(_: ActionType<typeof riskListRequest>) {
  try {
    const riskList: IRiskListElement[] = yield call(getList);
    yield put(riskListSuccess({ list: riskList }));
  } catch (error) {
    const { errorMessage } = parseError(error);
    yield put(riskListError({ errorMessage }));
  }
}

export function* riskSaveRequestSaga(action: ActionType<typeof riskSaveRequest>) {
  try {
    const risk: INewRiskSerialized = yield call(createRisk, action.payload.risk);
    yield put(riskSaveSuccess({ risk }));
  } catch (error) {
    const { errorMessage, errorStatus } = parseError(error);
    yield put(riskSaveError({ errorMessage }));
    displayRequestErrorToaster(
      errorStatus,
      'That risk already exists. Try choosing a different threat and asset combination.',
    );
  }
}
export function* riskSaveSuccessSaga(_: ActionType<typeof riskSaveSuccess>) {
  displaySavingSuccessMessage('risk of intelligence');
  yield put(riskListRequest());
}

export function* riskDeleteRequestSaga(action: ActionType<typeof riskDeleteRequest>) {
  try {
    yield call(deleteRisk, action.payload.uuid);
    yield put(riskDeleteSuccess());
  } catch (error) {
    const { errorMessage, errorStatus } = parseError(error);
    yield put(riskDeleteError({ errorMessage }));
    displayRequestErrorToaster(errorStatus);
  }
}
export function* riskDeleteSuccessSaga(_: ActionType<typeof riskDeleteSuccess>) {
  yield put(riskListRequest());
  displayDeletingSuccessMessage('Risk');
}
export function* projectSaveRequestSaga(action: ActionType<typeof projectSaveRequest>) {
  try {
    let project: IProjectElement;
    if ('uuid' in action.payload.project) {
      project = yield call(updateProject, action.payload.project);
    } else {
      project = yield call(createProject, action.payload.project);
    }
    yield put(projectSaveSuccess({ project }));
  } catch (error) {
    yield put(projectSaveError({ error }));
  }
}

export function projectSaveErrorSaga(action: ActionType<typeof projectSaveError>) {
  displayRequestErrorToaster(action.payload.error.status, 'Projects require a title.');
}

export function* projectSaveSuccessSaga(_: ActionType<typeof projectSaveSuccess>) {
  yield put(riskListRequest());
  displaySavingSuccessMessage('project');
}

export function* riskListFailedSaga(_: ActionType<typeof riskListError>) {
  yield alert('Error while retrieving the risk list');
}

export default function* riskListSaga() {
  yield takeEvery(getType(riskListRequest), riskListRequestSaga);
  yield takeEvery(getType(riskListError), riskListFailedSaga);
  yield takeEvery(getType(riskSaveRequest), riskSaveRequestSaga);
  yield takeEvery(getType(projectSaveRequest), projectSaveRequestSaga);
  yield takeEvery(getType(projectSaveSuccess), projectSaveSuccessSaga);
  yield takeEvery(getType(projectSaveError), projectSaveErrorSaga);
  yield takeEvery(getType(riskSaveSuccess), riskSaveSuccessSaga);
  yield takeEvery(getType(riskDeleteRequest), riskDeleteRequestSaga);
  yield takeEvery(getType(riskDeleteSuccess), riskDeleteSuccessSaga);
}
