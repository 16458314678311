import {
  IComparisonGraphData,
  IComparisonGraphFilter,
  IIntelligenceListFilter,
  INearbyIntelligenceListElement,
} from '@redux/Intelligence/types';
import { IMapTileAssetLocation, IPaginated, IPaginationParams } from '@redux/sharedTypes';
import axios from 'axios';
import { IAsset, IAssetListElement } from 'src/redux/Assets/types';
import getAPI, { getAxiosConfig, getAxiosFormDataConfig } from './request';

export async function getList(): Promise<IAssetListElement[]> {
  const api = await getAPI();
  const response = await api.api_assets_list();
  return response.body;
}
export async function getAssetById(assetId: string): Promise<IAsset> {
  const api = await getAPI();
  const response = await api.api_assets_read({ uuid: assetId });
  return response.body;
}
export async function deleteAsset(uuid: string): Promise<void> {
  const api = await getAPI();
  await api.api_assets_delete({
    uuid,
  });
}

export async function updateAsset(uuid: string, payload: FormData): Promise<IAsset> {
  const url = `/api/assets/${uuid}/`;
  const config = await getAxiosFormDataConfig(url, 'put', payload);
  const response = await axios(config);
  return response.data;
}

export async function createAsset(payload: FormData): Promise<IAsset> {
  const url = `/api/assets/`;
  const config = await getAxiosFormDataConfig(url, 'post', payload);
  const response = await axios(config);
  return response.data;
}

export async function getMapAssets(): Promise<IMapTileAssetLocation[]> {
  const url = `/api/assets/map/`;
  const config = await getAxiosConfig(url, 'get');
  const response = await axios(config);
  return response.data;
}

export async function getComparisonGraphAsset(
  params: IComparisonGraphFilter,
): Promise<IComparisonGraphData> {
  const url = `/api/assets/graph/comparison/`;
  const config = await getAxiosConfig(url, 'get', params);
  const response = await axios(config);
  return response.data;
}

export async function getNearbyIntelligence(
  uuid: string,
  distance: number,
  params?: IPaginationParams & IIntelligenceListFilter,
): Promise<IPaginated<INearbyIntelligenceListElement[]>> {
  const url = `/api/assets/${uuid}/intelligence/`;
  const config = await getAxiosConfig(url, 'get', { distance: distance, ...params });
  const response = await axios(config);
  return response.data;
}
