import { createStandardAction } from 'typesafe-actions';
import { IChangePasswordValues, ILoginValues } from './types';

export const loginUserRequest = createStandardAction('Login/USER_LOGIN_REQUEST')<ILoginValues>();
export const loginUserSuccess = createStandardAction('Login/USER_LOGIN_SUCCESS')();
export const loginUserError = createStandardAction('Login/USER_LOGIN_ERROR')<{
  errorMessage: string;
  status: number;
}>();
export const changePasswordRequest = createStandardAction(
  'Login/USER_CHANGE_PASSWORD_REQUEST',
)<IChangePasswordValues>();
export const changePasswordSuccess = createStandardAction('Login/USER_CHANGE_PASSWORD_SUCCESS')();
export const changePasswordError = createStandardAction('Login/USER_CHANGE_PASSWORD_ERROR')<{
  errorMessage: string;
}>();
export const simulateEnabledRequest = createStandardAction('Login/SIMULATE_ENABLED_REQUEST')();
export const simulateEnabledError = createStandardAction('Login/SIMULATE_ENABLED_ERROR')<{
  errorMessage: string;
}>();
export const simulateEnabledSuccess = createStandardAction('Login/SIMULATE_ENABLED_SUCCESS')<{
  simulateEnabled: boolean;
}>();
export default {
  loginUserRequest,
  loginUserSuccess,
  loginUserError,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordError,
  simulateEnabledRequest,
  simulateEnabledError,
  simulateEnabledSuccess,
};
