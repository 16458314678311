import { IExistingFile } from '../sharedTypes';

export interface INewSimulationClient {
  name: string;
  description: string;
  external_client_id: string;
  new_file: File | null;
  is_bound: boolean;
}
export interface IUserPermissions {
  read_only: boolean;
}
export interface ISimulationClient extends INewSimulationClient {
  uuid: string;
  files: IExistingFile[];
}
export interface ISimulationClientListElement {
  uuid: string;
  name: string;
  is_bound: boolean;
}

export interface IBlastCurve {
  [key: `${number}`]: number;
}
export interface ISimulationRealisticDisasterScenario {
  uuid: string;
  name: string;
  blast_curve: IBlastCurve;
}

export enum SIMULATION_TYPES {
  ACCUMULATION = 'ACC',
  RDS = 'RDS',
  SHAPESET = 'SHP',
}

export interface INewRDSRun extends INewSimulationRunBase {
  realistic_disaster_scenario: string;
  simulation_type: SIMULATION_TYPES.RDS;
}

export interface INewShapeSetRun extends INewSimulationRunBase {
  shapeset: string;
  simulation_type: SIMULATION_TYPES.SHAPESET;
}

export interface INewAccumulationRun extends INewSimulationRunBase {
  radius: number;
  simulation_type: SIMULATION_TYPES.ACCUMULATION;
}

export interface INewSimulationRunBase {
  name: string;
  simulation_client: string | null;
  status: RUN_STATUSES;
}

export type INewSimulationRunListElement = INewAccumulationRun | INewRDSRun | INewShapeSetRun;

export type ISimulationRunListElement = INewSimulationRunListElement & {
  uuid: string;
};

export interface ISimulationRunBase {
  uuid: string;
  name: string;
  result_csv?: IExistingFile;
}

export interface ISimulationAccumulationRun extends ISimulationRunBase {
  simulation_type: SIMULATION_TYPES.ACCUMULATION;
  radius: number;
  results: ISimulationResultRow[];
}

export interface ISimulationRDSRun extends ISimulationRunBase {
  simulation_type: SIMULATION_TYPES.RDS;
  realistic_disaster_scenario: ISimulationRealisticDisasterScenario;
  results: ISimulationResultRow[];
}

export interface ISimulationShapesetRun extends ISimulationRunBase {
  simulation_type: SIMULATION_TYPES.SHAPESET;
  shapeset: ISimulationShapeset;
  shapeset_results: ISimulationShapesetResultRow[];
}

export type ISimulationRun =
  | ISimulationAccumulationRun
  | ISimulationRDSRun
  | ISimulationShapesetRun;

export interface IPreviousRunTableState {
  data: ISimulationRunListElement[];
  loading: boolean;
  page: number;
  pages: number;
  pageSize: number;
}

export interface ISimulationResultRow {
  uuid: string;
  site_name: string;
  address_line: string;
  town_city: string;
  postcode_zipcode: string;
  total_pd: string;
  building_specific_total_bi: string;
  total_insured_value: number;
  simulation_output: number;
}
export interface ISimulationShapesetResultRow {
  uuid: string;
  simulation_shape_id: string;
  simulation_output: number;
  simulation_shape_metadata: Record<string, string>;
}
interface ISimulationAssetProperties {
  site_name: string;
}
export interface ISimulationShapeset {
  uuid: string;
  name: string;
  table_columns: { [key: string]: string };
  shapes: ShapeGeoJson;
}

export type SimulationShapesetTableRow = { [key: string]: string } & {
  pk: string | undefined;
  simulationOutput: number;
};

export type ShapeGeoJson = GeoJSON.FeatureCollection<GeoJSON.MultiPolygon>;

export type AssetFeatureCollection = GeoJSON.FeatureCollection<
  GeoJSON.Point,
  ISimulationAssetProperties
>;
interface IProperties {
  site_name: string;
  simulation_output: string;
  ordinal?: number;
  pk: string;
}

export type FeatureCollection = GeoJSON.FeatureCollection<GeoJSON.Point, IProperties>;

export interface IMapStyle {
  name: string;
  url: string;
  type: STYLE_TYPE;
}

export enum STYLE_TYPE {
  DARK = 'DARK',
  OUTDOORS = 'OUTDOORS',
  SATELLITE = 'SATELLITE',
}

export enum RUN_STATUSES {
  DONE = 'DONE',
  LOADING = 'LOADING',
  FAILED = 'FAILED',
}
