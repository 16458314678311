enum urls {
  LOGIN = '/',
  NEW_PASSWORD = '/new-password',
  DASHBOARD = '/dashboard',
  ASSETS = '/assets',
  THREATS = '/threats',
  RISKS = '/risks',
  INTELLIGENCE = '/intelligence',
  ANALYTICS = '/analytics',
  CONFIGURATION = '/configuration',
  NETWORK = '/network',
  HOME = '/home',
  SIMULATE = '/simulate',
}

export const BOUND_BOOK = 'boundbook';

export default urls;
