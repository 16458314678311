import {
  AssetFeatureCollection,
  FeatureCollection,
  INewSimulationRunListElement,
  ISimulationClient,
  ISimulationClientListElement,
  ISimulationRealisticDisasterScenario,
  ISimulationRun,
  ISimulationRunListElement,
  ISimulationShapeset,
  IUserPermissions,
} from '@redux/Simulate/types';
import { IPaginated, IPaginationParams } from '@redux/sharedTypes';
import axios from 'axios';
import getAPI, { getAxiosConfig, getAxiosFormDataConfig } from './request';

export async function getSimulationClientList(): Promise<ISimulationClientListElement[]> {
  const api = await getAPI();
  const response = await api.api_simulation_clients_list();
  return response.body;
}

export async function getSimulationBoundBookCsv(): Promise<Blob> {
  const url = `/api/simulation_bound_book/`;
  const config = await getAxiosConfig(url, 'get', {});
  const response = await axios({ ...config, responseType: 'blob' });
  return response.data;
}

export async function createSimulationClient(payload: FormData): Promise<ISimulationClient> {
  const url = `/api/simulation_clients/`;
  const config = await getAxiosFormDataConfig(url, 'post', payload);
  const response = await axios(config);
  return response.data;
}

export async function getSimulationClient(uuid: string): Promise<ISimulationClient> {
  const api = await getAPI();
  const response = await api.api_simulation_clients_read({ uuid });
  return response.body;
}

export async function updateSimulationClient(
  uuid: string,
  payload: FormData,
): Promise<ISimulationClient> {
  const url = `/api/simulation_clients/${uuid}/`;
  const config = await getAxiosFormDataConfig(url, 'put', payload);
  const response = await axios(config);
  return response.data;
}

export async function deleteSimulationClient(simulation_client_id: string): Promise<void> {
  const api = await getAPI();
  await api.api_simulation_clients_delete({
    uuid: simulation_client_id,
  });
}

export async function getSimulationRuns(
  params: IPaginationParams & { simulation_client: string; is_bound_book: boolean },
): Promise<IPaginated<ISimulationRunListElement[]>> {
  const url = `/api/simulation_runs/`;
  const config = await getAxiosConfig(url, 'get', params || {});
  const response = await axios(config);
  return response.data;
}

export async function createSimulationRun(
  simulationRun: INewSimulationRunListElement,
): Promise<ISimulationRunListElement> {
  const api = await getAPI();
  const response = await api.api_simulation_runs_create({
    data: simulationRun,
  });
  return response.body;
}

export async function getSimulationRun(uuid: string): Promise<ISimulationRun> {
  const api = await getAPI();
  const response = await api.api_simulation_runs_read({ uuid });
  return response.body;
}

export async function getMapSimulationAssets(
  simulation_client_id: string,
  page: number,
): Promise<AssetFeatureCollection> {
  const url = `/api/simulation_assets/`;
  const config = await getAxiosConfig(
    url,
    'get',
    { simulation_client_id, page, page_size: 10000 } || {},
  );
  const response = await axios(config);
  return response.data;
}

export async function getMapSimulationShapes(
  shapeset_id: string,
  page: number,
): Promise<AssetFeatureCollection> {
  const url = `/api/simulation_shapes/`;
  const config = await getAxiosConfig(url, 'get', { shapeset_id, page, page_size: 1000 } || {});
  const response = await axios(config);
  return response.data;
}

export async function getMapSimulationResults(simulation_run: string): Promise<FeatureCollection> {
  const url = `/api/simulation_runs/${simulation_run}/map/`;
  const config = await getAxiosConfig(url, 'get');
  const response = await axios(config);
  return response.data;
}

export async function getSimulationRealisticDisasterScenarioList(): Promise<
  ISimulationRealisticDisasterScenario[]
> {
  const api = await getAPI();
  const response = await api.api_simulation_realistic_disaster_scenarios_list();
  return response.body;
}

export async function getSimulationShapesetList(): Promise<ISimulationShapeset[]> {
  const api = await getAPI();
  const response = await api.api_simulation_shapesets_list();
  return response.body;
}

export async function getUserPerms(): Promise<IUserPermissions[]> {
  const url = `/api/users/`;
  const config = await getAxiosConfig(url, 'get');
  const response = await axios(config);
  return response.data;
}
